import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Button, LoadingIcon, Modal } from "@prosolve/common-components-ui"
import { checkHasPermissionShipmentTrackingEdit } from "../../../utils";
import useFetch from "../../../hooks/useFetch";
import ActionButton from "../../Shared/ActionButton";
import useShipmentSnackbar from "../../Shared/useShipmentSnackbar";
import useCreateOrderTemplates from "./useCreateOrderTemplates";
import CreateOrderForm from "./CreateOrderForm";
import useFetchOrders from "../../Shared/useFetchOrders";

export const CreateOrderModal = ({
  onClose: onCloseParam,
  updateOrderData,
  /** If supplied, the form will autopopulate data */
  opportunityId,
  /** If true, the form will autopopulate data using QFC items only */
  isOpportunityQfc: is_qfc = false,
  /** If supplied, the form will autopopulate data and become an edit form */
  order,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  //const [productsData, setProductsData] = useState([])
  //const [implementations, setImplementations] = useState([])

  const [formValues, setFormValues] = useState({})
  const [formSchema, setFormSchema] = useState({
    requiredFields: ['contactName', 'address', 'city', 'state', 'zip', 'requester'],
    optionalFields: ['accountId', 'opportunityId', 'implementationId', 'products', 'contactSearch', 'contactEmail', 'contactPhone', 'requestedDate', 'earliestArrivalDate', 'desiredArrivalDate', 'shippingInstructions'],
    initData: order
  })
  const orderTemplates = useCreateOrderTemplates()

  const [isBusy, setIsBusy] = useState(false);

  const shipmentSnackbar = useShipmentSnackbar()

  const createOrderFormRef = useRef(null);

  const fetch = useFetch();
  const { fetchCreateOrder } = useFetchOrders();

  const isEdit = !!order
  const hasEdit = checkHasPermissionShipmentTrackingEdit()

  const createOrder = async () => {
    setIsBusy(true)
    try {
      const jsonData = await fetchCreateOrder({ fetch, formValues, isEdit, is_qfc })
      //console.log('createOrder.jsonData', jsonData, 'jsonData.data', jsonData.data)

      if (!isEdit) jsonData.shouldRefreshImmediately = true
      updateOrderData(jsonData, true)
      onClose()
      
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error processing order', severity: 'failure' })
    }
    setIsBusy(false)
  }

  const onOpen = () => {
    document.documentElement.style.overflow = 'hidden'
  }

  const onClose = () => {
    resetForm();
    document.documentElement.style.overflow = 'auto'
    onCloseParam?.()
  }

  const onClickSubmit = () => {
    //console.log('CreateOrder not submitted', formValues)
    if (createOrderFormRef.current.isValid()) {
      createOrder()
    }
  }

  const resetForm = () => {
    if (opportunityId) {
      buildFromOpportunity()
    } else if (order) {
      const formSchemaClone = { ...formSchema }
      formSchemaClone.initData = order
      setFormSchema(formSchemaClone)
    } else {
      const formSchemaClone = { ...formSchema }
      formSchemaClone.initData = undefined
      setFormSchema(formSchemaClone)
    }
  }

  const buildFromOpportunity = async () => {
    setIsLoading(true)
    const formSchemaClone = { ...formSchema }
    if (is_qfc) {
      formSchemaClone.initData = await orderTemplates.buildOrderForOpportunityQFC(opportunityId, formSchema.initData?.accountId)
    } else {
      formSchemaClone.initData = await orderTemplates.buildOrderFromOpportunity(opportunityId, formSchema.initData?.accountId)
    }
    setFormSchema(formSchemaClone)
    setIsLoading(false)
  }

  const buildFromTestValues = () => {
    const formSchemaClone = { ...formSchema }
    formSchemaClone.initData = orderTemplates.buildOrderTestValues()
    setFormSchema(formSchemaClone)
  }

  useEffect(() => {
    onOpen()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetForm()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  useEffect(() => {
    if (opportunityId) {
      buildFromOpportunity()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId])

  useEffect(() => {
    const contact = formValues.contactSearch
    //console.log('createOrderButton.contactSearch update', contact)
    if (contact) {
      const formSchemaClone = { ...formSchema }
      formSchemaClone.initData = orderTemplates.updateOrderFromContact(formSchema.initData, contact)
      setFormSchema(formSchemaClone)
      //console.log('createOrderButton.contactSearch formSchema update', formSchemaClone)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.contactSearch])

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true}>
        {'Submit'}
      </Button>
    </div>
  )

  const modal = (
    <Modal title={<span onDoubleClick={buildFromTestValues}>{isEdit ? 'Edit' : 'Create'} Order</span>} onClose={onClose} footer={footer}>
      <div style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
      }}>
        {
          isLoading 
          ? <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}><LoadingIcon /></div>
          : <CreateOrderForm ref={createOrderFormRef} formSchema={formSchema} onFormValueChange={(values) => setFormValues(values)} />
        }
      </div>
    </Modal>
  )

  return (
    <>
      {hasEdit && 
        <>
          {createPortal(modal, document.body)}
        </>
      }
    </>
  )
}

export default ({
  showButton = true,
  updateOrderData,
  /** If supplied, the form will autopopulate data */
  opportunityId,
  /** If true, the form will autopopulate data using QFC items only */
  isOpportunityQfc = false,
  /** If supplied, the form will autopopulate data and become an edit form */
  order,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  
  const isEdit = !!order
  const hasEdit = checkHasPermissionShipmentTrackingEdit()

  return (
    <>
      {hasEdit && 
        <>
          {isOpen && 
            <CreateOrderModal 
              isOpen={isOpen} 
              onClose={() => setIsOpen(false)} 
              updateOrderData={updateOrderData} 
              opportunityId={opportunityId} 
              isOpportunityQfc={isOpportunityQfc} 
              order={order} 
            />
          }
          {showButton && 
            <ActionButton onClick={() => setIsOpen(true)}>
              {isEdit ? <>Edit&nbsp;Order</> : (isOpportunityQfc ? <>Training Boxes</> : <>New&nbsp;Order</>)}
            </ActionButton>
          }
        </>
      }
    </>
  )
}