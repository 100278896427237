import React, { useState, useEffect } from 'react';
import { SelectDropdown, Button, TabButton, LoadingIcon } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch';
import AccountSelector from './DataExplorerView/AccountSelector';
import ImplementationSelector from './DataExplorerView/ImplementationSelector';
import EventSelector from './DataExplorerView/EventSelector';
import Tabs from '../../sharedComponents/Tabs';

export default ({
  isLoading,
  assessmentNames
}) => {
  const fetch = useFetch();

  const [selectedAccount, setSelectedAccount] = useState()
  const [selectedImplementation, setSelectedImplementation] = useState()
  const [selectedEvent, setSelectedEvent] = useState()

  const onSetSelectedImplementation = (obj) => {
    setSelectedImplementation(obj)
    setSelectedEvent(undefined)
  }

  const onSetSelectedEvent = (obj) => {
    setSelectedEvent(obj)
    setSelectedImplementation(undefined)
  }

  useEffect(() => {
    setSelectedImplementation(undefined)
    setSelectedEvent(undefined)
  }, [selectedAccount])

  const selectedAccountId = selectedAccount && selectedAccount.Id ? selectedAccount.Id : '';
  const selectedImplementationId = selectedImplementation && selectedImplementation.Id ? selectedImplementation.Id : '';
  const selectedEventId = selectedEvent && selectedEvent.Id ? selectedEvent.Id : '';

  const [selectedAssessmentName, setSelectedAssessmentName] = useState();
  const [format, setFormat] = useState('Condensed');

  const formatDescription = format === 'Condensed' ?
    '*This format will put each assessment response on its own row, and each question will be on a dedicated column marked in the header with the answer in the matching column. However since all responses must boil down their questions to one single shared line, you will lose versioning and accuracy if a question has changed over time.' :
    '*This format is more accurate, but less readable. We can mantain version history of questions that may have changed over time since we will group each assessment response into multiple rows with a row for each question and answer.'

  const extractFileName = (response) => {
    const headers = response.headers;
    const contentDisposition = headers.get('Content-Disposition');
    const match = contentDisposition && contentDisposition.match(/filename=(.+)/);
    return match ? match[1] : 'data.csv'; // Default filename if not found
  }

  const downloadCsv = async () => {
    try {
      const response = await fetch(`assessment/report/download?name=${selectedAssessmentName}&format=${format}${selectedAccountId ? `&accountId=${selectedAccountId}` : ''}${selectedImplementationId ? `&implementationId=${selectedImplementationId}` : ''}${selectedEventId ? `&eventId=${selectedEventId}` : ''}`, { method: 'GET' })

      const blob = await response.blob(); 

      const fileName = extractFileName(response)

      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set filename for download
      document.body.appendChild(link);

      // Simulate click on the anchor element to trigger download
      link.click();

      // Cleanup: remove the anchor and revoke the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (e) {
      console.log(e)
    }
  }

  const tabs = ['Implementation', 'Event']
    
  const [selectedTab, setSelectedTab] = useState('Implementation')

  const assessmentNameOptions = assessmentNames.sort((a, b) => a.name.localeCompare(b.name)).map(({ name = '' }) => ({ text: name, value: name }))

  return (
    isLoading ?
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <LoadingIcon />
    </div>
    :
    <div style={{
      flex: 1,
      display: 'flex',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #c4c4c4',
      }}>
        <AccountSelector selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
        <div style={{
          color: '#565656',
          fontSize: '24px',
          fontWeight: 'bold',
          padding: '20px 20px 10px 20px'
        }}>
          {'Type'}
        </div>
        <div style={{ margin: '0px 0px 10px 0px', position: 'relative', left: '-0px' }}>
          <Tabs 
            tabs={tabs} 
            selectedTab={selectedTab} 
            setSelectedTab={setSelectedTab}
            textStyle={{ fontSize: '18px', padding: '8px 0px' }}
            suppressBorderBottom={true} 
            suppressSelectionBorderBottom={true} 
          />
        </div>
        {
          selectedTab === 'Implementation' ?
          <ImplementationSelector accountId={selectedAccount && selectedAccount.Id} selectedImplementation={selectedImplementation} setSelectedImplementation={onSetSelectedImplementation} />
          :
          selectedTab === 'Event' ?
          <EventSelector accountId={selectedAccount && selectedAccount.Id} selectedEvent={selectedEvent} setSelectedEvent={onSetSelectedEvent} />
          :
          <></>
        }
      </div>
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '20px'
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>Select Assessment:</div>
        <SelectDropdown
          id='select-assessment'
          options={assessmentNameOptions}
          onChange={(val) => setSelectedAssessmentName(val)}
        />
      </div>
      <div style={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '30px'
      }}>
        <div style={{ width: '150px' }}>CSV Format:</div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '20px',
        }}>
        {
          ['Condensed', 'Expanded'].map(tab => {
            return (
              <div key={tab} style={{ position: 'relative' }}>
              <TabButton
                key={tab}
                $selected={format === tab} 
                onClick={() => { 
                  if (format !== tab) {
                    setFormat(tab)
                  }
                }}
              >
                {tab}
              </TabButton>
              {
                tab === 'Condensed' &&
                <div style={{ position: 'absolute', bottom: '-15px', left: '14px', fontSize: '11px', color: '#565656', fontStyle: 'italic'}}>
                  {'*Recommended'}
                </div>
              }
              </div>
            )
          })
        }
        </div>
      </div>
      <div style={{ width: '600px', fontSize: '17px', color: '#565656', fontStyle: 'italic'}}>
      {formatDescription}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}>
        <Button
          style={{ width: '250px' }}
          $colored={true}
          $disabled={!selectedAssessmentName}
          onClick={() => {
            if (selectedAssessmentName) {
              downloadCsv()
            }
          }}
        >
          {'Download'}
        </Button>
      </div>
    </div>
    </div>
  )
}