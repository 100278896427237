import { useEffect, useState } from "react";
import { Button, LoadingIcon, Modal } from "@prosolve/common-components-ui";
import useFetch from "../../../hooks/useFetch";
import { checkHasPermissionShipmentTrackingPublish } from "../../../utils";
import ActionButton from "../../Shared/ActionButton"
import useShipmentSnackbar from "../../Shared/useShipmentSnackbar";
import useFetchOrders from "../../Shared/useFetchOrders";
import useFetchOpportunities from "../../Shared/useFetchOpportunities";
import Products from "./Products";

export const FinalizeOrderModal = ({
  order,
  updateSbOrderData,
  onClose: onCloseParam
}) => {
  const fetch = useFetch()
  const [isLoading, setIsLoading] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [overrequestedProducts, setOverrequestedProducts] = useState([])

  const shipmentSnackbar = useShipmentSnackbar()
  const { fetchFinalizeOrder } = useFetchOrders()
  const { fetchOpportunities } = useFetchOpportunities()

  const hasPublish = checkHasPermissionShipmentTrackingPublish()

  const finalizeOrder = async () => {
    setIsBusy(true)
    try {
      const jsonData = await fetchFinalizeOrder({ fetch, order })
      updateSbOrderData(jsonData.data, jsonData.dataSb)
      onClose()
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error finalizing order', severity: 'failure' })
    } finally {
      setIsBusy(false)
    }
  }

  /** 
   * Displays a warning if this orders products would exceed the opportunity purchased amount
   */
  const finalzeOrderVerify = async () => {
    setIsLoading(true)
    try {
      if (order.is_qfc || !order.opportunityId) return Promise.resolve()
      
      const opportunities = await fetchOpportunities({ fetch, opportunityId: order.opportunityId })
      const opportunity = opportunities[0]

      const quantityWarningMap = opportunity.OpportunityLineItems.records.reduce((acc, curr) => {
        const sku = curr.PricebookEntry.Product2.Shipbob_SKU_Code__c
        acc[sku] = (acc[sku] ?? 0) + curr.Quantity
        return acc
      }, {})

      // The same item can be purchased on multiple lines, so we need to sum up quantities by sku
      const overrequestedProducts = order.products
      .reduce((acc, curr) => {
        acc.filter(p => p.sku === curr.sku).forEach(p => p.quantity += curr.quantity)
        if (!acc.find(p => p.sku === curr.sku)) acc.push(curr)
        return acc
      }, [])
      .filter(p => {
        return (p.quantity > (quantityWarningMap[p.sku] || 0))
      })
      .map(p => {
        return {
          ...p,
          purchasedQuantity: (quantityWarningMap[p.sku] || 0)
        }
      })

      //console.log('overrequestedProducts', overrequestedProducts, 'quantityWarningMap', quantityWarningMap)
      setOverrequestedProducts(overrequestedProducts)
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error verifying order', severity: 'failure' })
    } finally {
      setIsLoading(false)
    }
  }

  const onClickSubmit = () => {
    finalizeOrder()
  }

  const onClose = () => {
    onCloseParam?.()
  }

  useEffect(() => {
    finalzeOrderVerify()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true}>
        {'Submit'}
      </Button>
    </div>
  )

  return (<>
    {hasPublish &&
      <Modal title={'Create Order'} onClose={onClose} footer={footer} isAutoHeight={true}>
        <div style={{
          fontSize: '16px',
          fontWeight: 'normal'
        }}>
          {isLoading
            ? <div style={{ textAlign: 'center' }}><LoadingIcon /></div>
            : <>
              <div style={{ marginBottom: '20px' }}>
                Once finalized, no changes can be made and the order will be sent to be processed.
              </div>
              <div style={{ marginBottom: '20px' }}>
                {overrequestedProducts.length > 0 && <>
                  <div style={{ marginBottom: '15px' }}>
                    <strong>Warning: </strong>
                    <span>The following products are about to be ordered that exceed the purchased amount...</span>
                  </div>
                  <div style={{ margin: '0 5px' }}>
                    <Products products={overrequestedProducts} isShowHeader={false} />
                  </div>
                </>}
              </div>
              <div style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                Do you wish to finalize the order?
              </div>
            </>
          }
        </div>
      </Modal>
    }
  </>)
}

export default ({
  order,
  updateSbOrderData
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const hasPublish = checkHasPermissionShipmentTrackingPublish()

  return (<>
    {hasPublish && <>
      {isOpen &&
        <FinalizeOrderModal onClose={() => setIsOpen(false)} order={order} updateSbOrderData={updateSbOrderData} />
      }
      <ActionButton onClick={() => setIsOpen(true)}>Finalize&nbsp;Order</ActionButton>
    </>}
  </>)
}