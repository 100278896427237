import React, { useEffect } from 'react';
import RouteErrorPage from './RouteErrorPage';
import ProgramRecap from './ProgramRecap/ProgramRecap'
import ShipmentTracking from './ShipmentTracking/ShipmentTracking';
import ShipmentTrackingHome from './ShipmentTracking/ShipmentTrackingHome';
import OrderTrackingHome from './Shipments/OrderTrackingHome';
import QuestCodesManagement from './QuestCodesManagement/QuestCodesManagement';
import FeedbackWizardView from './FeedbackWizardView/FeedbackWizardView';
import AssessmentManager from './AssessmentManager/AssessmentManager';
import AssessmentBuilder from './AssessmentManager/AssessmentBuilder/AssessmentBuilder';
import AssessmentReports from './AssessmentManager/AssessmentReports/AssessmentReports';
import AddEditAssessment from './AssessmentManager/AssessmentBuilder/AddEditAssessment/AddEditAssessment';
// import useMediaQuery from './hooks/useMediaQuery';
import GlobalData, { GlobalDataContext } from './GlobalData'
import Router from './Router';
import '@prosolve/common-components-ui/dist/styles.css'
import { Outlet } from 'react-router-dom';

// Used to wrap route and override title seen in the browser Tab
const Page = (props) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
  return props.children;
};

export default () => {
  // const isMobileView = useMediaQuery('(max-width: 450px)');

  const globalData = new GlobalData();
  // globalData.setIsMobileView(isMobileView)

  const routes = [
    {
      path: "/",
      element: (routeProps) => <RouteErrorPage {...routeProps} />,
      errorElement: (routeProps) => <RouteErrorPage {...routeProps} />,
    },
    {
      path: "programRecap",
      element: (routeProps) => (
        <Page title='ProSolve Program Recap'>
          <ProgramRecap {...routeProps} />
        </Page>
      ),
    },
    {
      path: "shipping",
      isPrivate: true,
      element: (routeProps) => (
        <Page title='ProSolve Order Tracker v2.5'>
          <Outlet {...routeProps} />
        </Page>
      ),
      children: [
        {
          path: "",
          element: <OrderTrackingHome />
        },
        {
          path: ":tab",
          element: <OrderTrackingHome />
        },
      ]
    },
    {
      path: "shipmentTracking",
      element: (routeProps) => (
        <Page title='ProSolve Shipment Tracker'>
          <ShipmentTrackingHome {...routeProps} />
        </Page>
      ),
    },
    {
      path: "shipmentTracking/account",
      element: (routeProps) => (
        <Page title='ProSolve Shipment Tracker'>
          <ShipmentTracking {...routeProps} />
        </Page>
      ),
    },
    {
      path: "studentSurvey",
      element: (routeProps) => (
        <Page title='ProSolve Student Survey'>
          <FeedbackWizardView {...routeProps} />
        </Page>
      ),
    },
    {
      path: "survey",
      element: (routeProps) => (
        <Page title='ProSolve Survey'>
          <FeedbackWizardView {...routeProps} />
        </Page>
      ),
    },
    {
      path: "questCodes",
      isPrivate: true,
      element: (routeProps) => (
        <Page title='Quest Codes Management'>
          <QuestCodesManagement {...routeProps} />
        </Page>
      ),
    },
    {
      path: "assessmentManager",
      isPrivate: true,
      element: (routeProps) => (
        <Page title='Assessment Manager'>
          <AssessmentManager {...routeProps} />
        </Page>
      ),
      children: [
        {
          path: "builder",
          element: <AssessmentBuilder />
        },
        {
          path: "builder/:assessmentName",
          element: <AddEditAssessment />
        },
        {
          path: 'reports',
          element: <AssessmentReports />
        }
      ]
    },
  ]

  return (
    <GlobalDataContext.Provider value={globalData}>
      <Router routes={routes} />
    </GlobalDataContext.Provider>
  )
}