import { Children, cloneElement, isValidElement } from "react"
import styled from "styled-components"

const FormFieldWrapper = styled.div`
  flex: 1 1 0%; 
  width: auto; 
  display: flex; 
  flex-direction: column;
`

const FormFieldLabel = styled.div`
  margin-bottom: 5px; 
  font-weight: bold; 
  font-size: 14px; 
  color: rgb(86, 86, 86);
`

const FormFieldErrors = styled.div`
  display: none;
  color: rgb(239, 12, 12);
  font-size: 11px;
  font-weight: normal;
  font-style: italic;

  .required & {
    display: inline;  
  }
`

export default ({isEnabled, label, isInvalid = false, children}) => {
  return (<>
    {
      !!isEnabled ? 
        <FormFieldWrapper className={isInvalid && ' required '}>
          <FormFieldLabel>
            <span>{label}</span>
            {isInvalid && 
              <FormFieldErrors> *required</FormFieldErrors>}
          </FormFieldLabel>
          {Children.map(children, c => 
            (isValidElement(c) ? cloneElement(c, (isInvalid ? {isInvalid} : {} )) : c)
          )}
        </FormFieldWrapper> :

        <>{children}</>
    }
  </>)
}