import { useEffect, useRef, useState } from "react"
import useEmailTemplates from "../Shared/useEmailTemplates"
import { LoadingIcon } from "@prosolve/common-components-ui"
import { ShadowRoot } from "./ShadowRoot"
import styled from "styled-components"

const StyledIFrame = styled.iframe`
  width: 100%;
  border: solid 1px silver;
`

export default function EmailTemplate({ emailTemplateId, order, height = '400px' }) {
  const emailRootRef = useRef()
  const { fetchApplyTemplate: fetchApplyTemplateParam } = useEmailTemplates()

  const [isLoading, setIsLoading] = useState(false)
  const [body, setBody] = useState()

  const fetchApplyTemplate = async () => {
    setIsLoading(true)
    const body = {
      emailTemplateId,
      data: { order }
    }
    const result = await fetchApplyTemplateParam(body)
    setBody(result.body)
    setIsLoading(false)
  }

  const displayEmailIFrame = () => {
    if (!emailRootRef.current) return
    var doc = emailRootRef.current.contentDocument;
    doc.write(body)
    doc.close()
  }

  useEffect(() => {
    fetchApplyTemplate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateId, order])

  useEffect(() => {
    displayEmailIFrame()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, body])

  return (
    <div>
      {isLoading ? (
        <div style={{ height }}><LoadingIcon /></div>
      ) : (
        <StyledIFrame title={`email-template-${emailTemplateId}`} ref={emailRootRef} height={height}></StyledIFrame>
      )}

      {/* Alternatives that don't use iframe */}
      { false && <>
        <div style={{ border: 'solid 1px silver' }}>
          <ShadowRoot><div dangerouslySetInnerHTML={{ __html: body }}></div></ShadowRoot>
        </div>
        <div dangerouslySetInnerHTML={{ __html: body }} style={{ border: 'solid 1px silver' }}></div>
      </>}
    </div>
  )
}