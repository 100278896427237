import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import Papa from 'papaparse';
import { FaDownload, FaFileMedical } from "react-icons/fa";
import { LoadingIcon } from "@prosolve/common-components-ui";
import { downloadFile } from "../../utils";
import ActionButton from "../Shared/ActionButton";
import useOpportunityExport from "../Shared/useOpportunityExport";
import ShippingModal from "../Shared/ShippingModal";
import OrderList from "../Orders/OrderList";
import useOrders from "../Shared/useOrders";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import ImplementationSelector from "../Shared/ImplementationSelector";

const StyledModal = styled.div`
  color: rgb(126 126 126);
  font-size: 14px;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;

  & .strong {
    font-weight: bold;
  }
`

const StyledDropzone = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 15px;
  border: dashed 1px silver;
  background: rgba(0, 0, 0, 0.03);
`

const StyledList = styled.ol`
  margin: 0;
`

const StyledUnorderedList = styled.ul`
  margin: 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 15px;
  margin-bottom: 20px;
  border-top: solid 3px rgb(2, 128, 198);
  border-left: solid 1px rgb(0 0 0 / 10%);
  border-right: solid 1px rgb(0 0 0 / 10%);
  border-bottom: solid 1px rgb(0 0 0 / 10%);
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  background: rgba(0, 0, 0, 0.01);

  &.alt {
    background: white;
  }
`

const SectionHeader = styled.div`
  color: gray;
  font-weight: bold;
`

export const ImportOpportunityModal = ({
  opportunity,
  onCreateOrders,
  onClose: onCloseParam
}) => {
  const { AccountId } = opportunity

  const { createOrder } = useOrders()
  const { newMessage } = useShipmentSnackbar()
  const { createImportTemplate, processImport } = useOpportunityExport()

  const [isBusy, setIsBusy] = useState(false)
  const [isParsingFile, setIsParsingFile] = useState(false)
  const [parseMessages, setParseMessages] = useState([])
  const [createOrderMessages, setCreateOrderMessages] = useState([])
  const [files, setFiles] = useState([])
  const [implementation, setImplementation] = useState()
  const [ordersEx, setOrdersEx] = useState([])
  
  const ordersScrollToRef = useRef(null)

  const onDropAccepted = useCallback((acceptedFiles) => {
    setOrdersEx([])
    setFiles(acceptedFiles)

    const file = acceptedFiles[0]

    setIsParsingFile(true)
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const orders = await processImport(opportunity, implementation, result.data)
        
        setOrdersEx([])
        setParseMessages([])

        if (!orders || orders.length === 0) {
          setParseMessages(['no orders found'])
        }
        else if (orders.errors) {
          setParseMessages(orders.errors)
        } 
        else {
          const ordersEx = orders.map(o => ({
            // These extra fields shouldn't be saved, but are needed to display properly in the orders card
            data: {
              created_date: new Date(),
              project: opportunity.Name,
              ...o
            },
            dataImport: o
          }))
          //console.log('result', result, 'orders', orders)
          setOrdersEx(ordersEx)
  
          if (result.errors) {
            console.log(result.errors)
            const errorStr = result.errors.map(e => `(row ${e.row}) ${e.message}`)
            setParseMessages(errorStr)
          }
        }

        setIsParsingFile(false)
      },/*
      // Can't do this until we know the format of these params
      error: (err, file, inputElem, reason) => {
        console.log('error', err, file, inputElem, reason)
        //setParseErrors(err)
      }*/
    })
  }, [implementation, opportunity, processImport])

  const {getRootProps, getInputProps} = useDropzone({
    onDropAccepted,
    accept: {
      'text/csv': ['.csv']
    },
    maxFiles: 1,
    maxSize: 1048576
  });

  const onDownloadTemplate = async () => {
    const template = await createImportTemplate(opportunity)
    downloadFile({
      filename: `Bulk Order Template.csv`,
      fileDataParts: [Papa.unparse([template])],
      type: 'file/csv'
    })
  }

  const onClose = () => {
    setFiles([])
    setOrdersEx([])
    setParseMessages([])
    onCloseParam?.()
  }

  const onSubmit = async () => {
    if (ordersEx?.length <= 0) return

    setIsBusy(true)

    const orderPromises = ordersEx.map((o,i) => {
      return createOrder(o.dataImport)
    })

    const orderResultArr = await Promise.allSettled(orderPromises)
    orderResultArr.forEach((or, i) => {
      if (or.status === 'rejected' || or.value?.error) {
        ordersEx[i].error = or.value?.error?.message || or.value?.error || 'unknown error'
      }
    })

    if (ordersEx.find(o => !o.error)) {
      if (onCreateOrders) onCreateOrders()
    }

    if (ordersEx.find(o => o.error)) {
      newMessage({ message: `Some orders were not created`, severity: 'failure' })
      setCreateOrderMessages(ordersEx.map(o => o.error))
      setOrdersEx(ordersEx.filter(o => o.error))
    } else {
      newMessage({ message: `${ordersEx.length} new orders created`, severity: 'success' })
      onClose()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (parseMessages?.length > 0 || ordersEx?.length > 0) {
      ordersScrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [ordersEx, parseMessages])

  return (
    <ShippingModal title='Create Orders in Bulk' isBusy={isBusy} onClose={onClose} onSubmit={onSubmit}>
      <StyledModal>
        <Section>
          <SectionHeader>Instructions</SectionHeader>
          <div>Create multiple orders at once by uploading a file with the necessary fields as columns.</div>
          <div className="strong">To bulk create orders...</div>
          <StyledList>
            <li>Select an Implementation (optional)</li>
            <li>Download the template</li>
            <li>Enter each order as a new line in the template</li>
            <li>{`Save the file as a .csv (If you are using Numbers on a Mac, export to a CSV by clicking File > Export To > CSV)`}</li>
            <li>Upload the .csv file</li>
            <li>Submit the orders</li>
          </StyledList>
        </Section>

        <Section>
          <div className="strong">Implementation</div>
          <ImplementationSelector isShowLabel={false} accountId={AccountId} value={implementation} onChange={setImplementation} />
        </Section>

        <Section>
          <div className="strong">Download Template</div>
          <ActionButton onClick={onDownloadTemplate} style={{ }}>
            <FaDownload style={{ marginRight: '1em', position: 'relative', top: '-1px' }} /> Bulk Orders Template
          </ActionButton>

          <div className="strong">Upload Template</div>
          <StyledDropzone {...getRootProps()}>
            <input {...getInputProps()} />
            {
              isParsingFile 
              ? <LoadingIcon />
              : files?.length > 0 
                ? files?.map((f,i) => 
                    <div key={i}>{f.name}</div>
                  ) 
                : <div style={{ color: 'rgb(126 126 126)', textAlign: 'center' }}>
                    <div style={{ fontSize: '32px' }}><FaFileMedical /></div>
                    <div>Drag and drop or <span style={{ color: 'rgb(2, 128, 198)' }}>browse</span> your files here</div>
                  </div>
            }
          </StyledDropzone>

          <div ref={ordersScrollToRef}></div>
          {parseMessages?.length > 0 && 
            <div>
              <div className="strong">Import Errors</div>
              <StyledUnorderedList>
                {parseMessages?.map((m,i) => <li key={i}>{m}</li>)}
              </StyledUnorderedList>
            </div>
          }
        </Section>

        {ordersEx?.length > 0 && 
          <Section className="alt">
            <SectionHeader>Orders</SectionHeader>
            {createOrderMessages?.length > 0 && 
              <div>
                <div className="strong">Create order errors:</div>
                <StyledUnorderedList>
                  {createOrderMessages?.map((m,i) => <li key={i}>{m}</li>)}
                </StyledUnorderedList>

                <div className="strong" style={{ marginTop: '15px' }}>The orders below have not been created:</div>
              </div>
            }
            <OrderList orders={ordersEx} />
          </Section>
        }
      </StyledModal>
    </ShippingModal>
  )
}

export default ({
  opportunity,
  onCreateOrders
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (<>
    {isOpen && 
      <ImportOpportunityModal onClose={() => setIsOpen(false)} opportunity={opportunity} onCreateOrders={onCreateOrders} />
    }
    <ActionButton onClick={() => setIsOpen(true)}>
      Import
    </ActionButton>
  </>)
}