import React, { useState, useEffect } from 'react';
import useFetch from '../../../hooks/useFetch';
import AccountSelector from './AccountSelector';
import AssessmentSelector from './AssessmentSelector';
import ImplementationSelector from './ImplementationSelector';
import EventSelector from './EventSelector';
import ChatTypeSelector from './ChatTypeSelector';
import DataChart from './DataChart/DataChart';
import processAnalytics from './DataChart/processAnalytics';
import Tabs from '../../../sharedComponents/Tabs';
import specialAnalyticsConfig from './DataChart/processAnalyticsConfig.json'

export default () => {
  const [chartType, setChartType] = useState('Questions');
  
  const [processedAnalytics, setProcessedAnalytics] = useState(false);

  const [isPercentage, setIsPercentage] = useState(true);

  const [selectedAssessmentName, setSelectedAssessmentName] = useState('ApexAcademyEndProgram');

  const [hasTags, setHasTags] = useState(false);

  const chartTypes = hasTags ? ['Questions','Tags'] : ['Questions'];

  if (!chartTypes.includes(chartType)) {
    setChartType(chartTypes[0])
  }

  const hasProcessedAnalytics = Boolean(specialAnalyticsConfig[selectedAssessmentName]);

  const onAssessementClicked = (assessmentName) => {
    setSelectedAssessmentName(assessmentName)
  }

  const fetch = useFetch();

  const [isLoading, setIsLoading] = useState(true)
  const [analyticsData, setAnalyticsData] = useState();

  const [selectedAccount, setSelectedAccount] = useState()

  const [selectedImplementation, setSelectedImplementation] = useState()

  const [selectedEvent, setSelectedEvent] = useState()

  const onSetSelectedImplementation = (obj) => {
    setSelectedImplementation(obj)
    setSelectedEvent(undefined)
  }

  const onSetSelectedEvent = (obj) => {
    setSelectedEvent(obj)
    setSelectedImplementation(undefined)
  }

  useEffect(() => {
    setSelectedImplementation(undefined)
    setSelectedEvent(undefined)
  }, [selectedAccount])

  const selectedAccountId = selectedAccount && selectedAccount.Id ? selectedAccount.Id : '';

  const selectedImplementationId = selectedImplementation && selectedImplementation.Id ? selectedImplementation.Id : '';

  const selectedEventId = selectedEvent && selectedEvent.Id ? selectedEvent.Id : '';

  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const url = `assessment/analytics/answerCounts?name=${selectedAssessmentName}${selectedAccountId ? `&accountId=${selectedAccountId}` : ''}${selectedImplementationId ? `&implementationId=${selectedImplementationId}` : ''}${selectedEventId ? `&eventId=${selectedEventId}` : ''}`;
      const response = await fetch(url, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAnalyticsData(undefined)
      } else {
        setAnalyticsData(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAnalyticsData(undefined)
      setIsLoading(false);
    }
  }

  const fetchTagsAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const url = `assessment/analytics/tagsAnswerCounts?name=${selectedAssessmentName}${selectedAccountId ? `&accountId=${selectedAccountId}` : ''}${selectedImplementationId ? `&implementationId=${selectedImplementationId}` : ''}${selectedEventId ? `&eventId=${selectedEventId}` : ''}`;
      const response = await fetch(url, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAnalyticsData(undefined)
      } else {
        setAnalyticsData(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAnalyticsData(undefined)
      setIsLoading(false);
    }
  }

  const finalAnalyticsData = processedAnalytics ? processAnalytics(selectedAssessmentName, analyticsData) : analyticsData;

  useEffect(() => {
    if (chartType === 'Questions') {
      fetchAnalyticsData()
    } else {
      fetchTagsAnalyticsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssessmentName, selectedAccountId, selectedImplementationId, selectedEventId, chartType])

  const tabs = ['Implementation', 'Event']
  
  const [selectedTab, setSelectedTab] = useState('Implementation')

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      // padding: '20px'
      // justifyContent: 'center',
      // alignItems: 'center'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #c4c4c4',
      }}>
        <AccountSelector selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
        <div style={{
          color: '#565656',
          fontSize: '24px',
          fontWeight: 'bold',
          padding: '20px 20px 10px 20px'
        }}>
          {'Type'}
        </div>
        <div style={{ margin: '0px 0px 10px 0px', position: 'relative', left: '-0px' }}>
          <Tabs 
            tabs={tabs} 
            selectedTab={selectedTab} 
            setSelectedTab={setSelectedTab}
            textStyle={{ fontSize: '18px', padding: '8px 0px' }}
            suppressBorderBottom={true} 
            suppressSelectionBorderBottom={true} 
          />
        </div>
        {
          selectedTab === 'Implementation' ?
          <ImplementationSelector accountId={selectedAccount && selectedAccount.Id} selectedImplementation={selectedImplementation} setSelectedImplementation={onSetSelectedImplementation} />
          :
          selectedTab === 'Event' ?
          <EventSelector accountId={selectedAccount && selectedAccount.Id} selectedEvent={selectedEvent} setSelectedEvent={onSetSelectedEvent} />
          :
          <></>
        }
        <AssessmentSelector
          selectedAssessmentName={selectedAssessmentName}
          onAssessementClicked={onAssessementClicked}
          setHasTags={setHasTags}
        />
      </div>

      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <ChatTypeSelector
          isPercentage={isPercentage}
          setIsPercentage={setIsPercentage}
          hasProcessedAnalytics={hasProcessedAnalytics}
          processedAnalytics={processedAnalytics}
          setProcessedAnalytics={setProcessedAnalytics}
          chartType={chartType} 
          setChartType={setChartType}
          chartTypes={chartTypes}
        />
        <DataChart selectedAssessmentName={selectedAssessmentName} analyticsData={finalAnalyticsData} isLoading={isLoading} isPercentage={isPercentage} />
      </div>

    </div>
  )
}