import { useEffect, useState } from "react"
import ToggleButton from "../Shared/ToggleButton"
import AccountDropdown from "../Shared/AccountDropdown"
import useOrderType from "../Shared/useOrderType"

export default ({
  value: defaultValue,
  orders: defaultOrders,
  onFilteredOrdersChange
}) => {
  const [filter, setFilter] = useState(defaultValue || {})
  const [orders, setOrders] = useState(defaultOrders)

  const { draftTypes, orderTypes } = useOrderType()

  const { draft, order } = filter

  const onAccountChange = (val) => {
    const filterClone = {
      ...filter,
      accountId: val
    }
    setFilter(filterClone)
  }

  const onDraftChange = (val) => {
    const filterClone = {
      ...filter,
      order: undefined,
      draft: (filter.draft === val ? undefined: val)
    }
    setFilter(filterClone)
  }

  const onOrderChange = (val) => {
    const filterClone = {
      ...filter,
      draft: undefined,
      order: (filter.order === val ? undefined: val)
    }
    setFilter(filterClone)
  }

  const updateFilteredOrders = () => {
    const filteredOrders = orders.filter(o => {
      const { accountId, draft, order } = filter
      if (accountId && accountId !== '[noaccount]' && o.data?.accountId !== accountId) return false
      if (accountId === '[noaccount]' && o.data?.accountId) return false
      if (draft && draft !== 'All' && (o.data?.status || '').toLowerCase() !== draft.toLowerCase()) return false
      if (draft === 'All' && o.data?.status) return false
      if (order && order !== 'All' && (o.dataSb?.status || '').toLowerCase() !== order.toLowerCase()) return false
      if (order === 'All' && o.dataSb?.status) return false
      return true
    }).slice(0, 100)
    onFilteredOrdersChange(filteredOrders)
  }

  useEffect(() => {
    setOrders(defaultOrders)
  }, [defaultOrders])

  useEffect(() => {
    updateFilteredOrders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, filter])

  return (
    <>
      <div style={{ paddingBottom: '20px' }}>
        <AccountDropdown onChange={onAccountChange} isShowNoAccountOption={true} />
      </div>

      <div style={{ display: 'flex', paddingBottom: '20px', positive: 'relative', marginLeft: '-5px' }}>
        {draftTypes.map(d => 
          (d.id !== 'All' ?
            <ToggleButton key={d.id} onClick={() => onDraftChange(d.id)} on={(draft === d.id).toString()}>{d.buttonTitle}</ToggleButton> :

            <span key={d.id} style={{ display: 'flex' }}>
              <ToggleButton 
                onClick={() => onDraftChange(d.id)} 
                on={(draft === d.id).toString()} 
                style={{ width: '120px' }}
              >
                {d.buttonTitle}
              </ToggleButton>
              <div style={{ borderRight: 'solid 1px silver', margin: '0 10px' }}></div>
            </span>
          )
        )}
      </div>

      <div style={{ display: 'flex', paddingBottom: '20px', positive: 'relative', marginLeft: '-5px' }}>
        {orderTypes.map(o => 
          (o.id !== 'All' ?
            <ToggleButton key={o.id} onClick={() => onOrderChange(o.id)} on={(order === o.id).toString()}>{o.buttonTitle}</ToggleButton> :

            <span key={o.id} style={{ display: 'flex' }}>
              <ToggleButton 
                onClick={() => onOrderChange(o.id)} 
                on={(order === o.id).toString()} 
                style={{ width: '120px' }}
              >
                {o.buttonTitle}
              </ToggleButton>
              <div style={{ borderRight: 'solid 1px silver', margin: '0 10px' }}></div>
            </span>
          )
        )}
      </div>
    </>
  )
}