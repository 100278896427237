const fetchOpportunities = async (options) => {
  const { fetch } = options
  delete options.fetch

  options.isPhysicalOnly = (options.isPhysicalOnly !== undefined ? options.isPhysicalOnly : true)
  options.isPhysicalLineItemsOnly = (options.isPhysicalLineItemsOnly !== undefined ? options.isPhysicalLineItemsOnly : true)
  const params = new URLSearchParams(Object.entries(options).filter(e => e[1] !== undefined))

  const result = await fetch(`accounts/opportunitiesSummary${params.size > 0 ? `?${params.toString()}` : ''}`)
  const resultJson = await result.json()
  return resultJson
}

const fetchOpportunityLineItemRefresh = async (options) => {
  const { fetch } = options
  delete options.fetch

  const params = new URLSearchParams(Object.entries(options).filter(e => e[1] !== undefined))

  const result = await fetch(`accounts/opportunity/updateLineItemSummary${params.size > 0 ? `?${params.toString()}` : ''}`)
  const resultJson = await result.json()
  return resultJson
}

export default () => {
  return {
    fetchOpportunities,
    fetchOpportunityLineItemRefresh
  }
}