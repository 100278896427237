import React, { useState } from "react";
import useForm from '../hooks/useForm';
import { FormField, Button, LoadingIcon } from '@prosolve/common-components-ui'
import useFetch from '../hooks/useFetch';
import useMediaQuery from '../hooks/useMediaQuery';
import FeedbackWizard from './FeedbackWizard/FeedbackWizard';
import TeamSelector from "./TeamSelector";

export default (routeProps = {}) => {
  const { path } = routeProps
  
  const isSmallView = useMediaQuery('(max-width: 620px)');

  const fetch = useFetch();

  const [isBusy, setIsBusy] = useState(false);
  const [showInvalidLogin, setShowInvalidLogin] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const userId = urlParams.get('userId');
  const classId = urlParams.get('classId');
  const eventId = urlParams.get('eventId');
  const assessmentName = urlParams.get('assessmentName');

  let initSurveyData;
  if (path === 'survey' && assessmentName) {
    initSurveyData = {
      userId: userId || '',
      eventId,
      assessmentName
    }
  } else if (userId && classId && assessmentName) {
    initSurveyData = {
      userId,
      classId,
      assessmentName
    }
  }

  const [surveyData, setSurveyData] = useState(initSurveyData);

  const [showTeamSelector, setShowTeamSelector] = useState(false)

  const [isSurveyComplete, setIsSurveyComplete] = useState(false);

  const formSchema = {
    requiredFields: ['code'],
    initData: {
      code: ''
    }
  }

  const formData = useForm(formSchema)

  const onClickSubmit = async () => {
    if (formData.isValid() && !isBusy) {
      setIsBusy(true)

      const { code: codeRaw = '' } = formData.values;

      const code = codeRaw.toUpperCase();

      const response = await fetch(
        `quest/code/redeemed?code=${code}`, 
        { 
          method: 'GET',
        }
      )

      const jsonData = await response.json() || {};

      const { 
        success,
        code: questCode = '',
        classId = '',
        seasonIndex = ''
      } = jsonData;

      if (success && questCode && seasonIndex) {
        setShowInvalidLogin(false)

        const isStarforce = seasonIndex === 201;

        const openSurveyObj = {
          classId,
          assessmentName: isStarforce ? 'StudentStarforcePostProgramSurvey' : 'StudentApexPostProgramSurvey'
        }

        if (!isStarforce) {
          setShowTeamSelector(true)
        }

        setSurveyData(openSurveyObj);
      } else {
        setShowInvalidLogin(true)
      }

      setIsBusy(false)
    }
  }

  const onTeamColorSelected = (teamColor) => {
    console.log(teamColor);

    const updatedSurveyData = {
      ...surveyData,
      teamColor,
    }

    setSurveyData(updatedSurveyData);
    setShowTeamSelector(false)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickSubmit();
    }
  }

  return (
    isSurveyComplete ?
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url("https://api.portal.questboxes.com/api/file/Product%20Images/apex-pre-survey-background.png") top left/cover no-repeat fixed`
    }}>
      <div style={{
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '48px',
        textAlign: 'center'
      }}>
        {'Thank You For Completing Our Survey!'}
      </div>
    </div>
    :
    showTeamSelector && Boolean(surveyData) ?
    <TeamSelector onTeamColorSelected={onTeamColorSelected} />
    :
    !showTeamSelector && Boolean(surveyData) ?
    <FeedbackWizard assessmentName={surveyData.assessmentName} classId={surveyData.classId} eventId={surveyData.eventId} userId={surveyData.userId} teamColor={surveyData.teamColor} onSuccess={() => setIsSurveyComplete(true)} />
    :
    <div style={{
      display: 'flex',
      flexDirection: isSmallView ? 'column' : 'row',
      rowGap: '15px',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'auto',
      overflowX: 'hidden',
      background: `url("https://api.portal.questboxes.com/api/file/Product%20Images/apex-pre-survey-background.png") top left/cover no-repeat fixed`
    }}>

      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isSmallView ? 'flex-start' : 'center',
        alignItems: 'center',
        padding: '20px',
        zIndex: '100',
        marginTop: isSmallView ? '-15px' : '0px'
      }}>

       <div style={{ display: 'flex', flexDirection: 'column' }}>

          <div style={{ flex: 1, display: 'flex', marginBottom: '20px', textAlign: isSmallView ? 'center' : 'center' }}>
            <div style={{ color: '#ffffff'}}>
              <div style={{ fontWeight: 'bold', fontSize: '40px', color: '#ffc200' }}>{"ENTER CLASS CODE"}</div>
            </div>
          </div>

          <FormField
            fieldStyle={{ 
              fontSize: '18px', 
              textAlign: isSmallView ? 'center' : 'left',
              textTransform: 'uppercase'
            }}
            // label='Code'
            labelStyle={{ color: '#ffffff', fontWeight: 'bold'}}
            fieldId='code'
            type='text'
            formData={formData}
            onKeyDown={onKeyDown}
          />

          <div style={{ height: '25px', position: 'relative'}}>

          {
            (!isBusy && showInvalidLogin) ?
            <div style={{
              color: '#ef0c0c',
              fontStyle: 'italic',
              fontSize: '14px',
              position: 'absolute',
              top: '3px',
              left: '3px'
            }}>
              {'Code not found or has not been redeemed by your teacher.'}
            </div>
            :
            <div>&nbsp;</div>
          }

          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallView ? 'center' : 'center' }}>
            <Button 
              style={{ 
                display: 'inline-flex', 
                marginRight: '10px', 
                fontWeight: 'bold', 
                fontSize: '24px',
                width: '150px', 
                height: '40px',
                background: 'transparent'
              }} 
              onClick={onClickSubmit} 
              $colored={true}
            >
                {'SUBMIT'}
            </Button>
            {
              isBusy &&
              <LoadingIcon />
            }
          </div>

        </div>
      </div>
    </div>
  )
}