import { useState } from "react";
import styled from "styled-components";
import Papa from "papaparse";
import useFetch from "../../hooks/useFetch";
import { downloadFile } from "../../utils";
import ActionButton from "../Shared/ActionButton";
import useFetchOrders from "../Shared/useFetchOrders";
import useOpportunityExport from "../Shared/useOpportunityExport";

const AnimatedText = ({ isAnimated, children }) => {
  return <span className={isAnimated ? 'animate' : ''}>
    <AnimatedTextStyled>{children}</AnimatedTextStyled>
  </span>
}
const AnimatedTextStyled = styled.div`
  .animate & {
    color: #0280c6;
    animation: 1s ease-in infinite alternate blink;
  }

  @keyframes blink {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0.3;
    }
  }
`

export const useDownloadOpportunity = () => {
  const fetch = useFetch()
  const { fetchOrders: fetchOrdersHook } = useFetchOrders()

  const { createExport } = useOpportunityExport()

  const fetchOrders = async (opportunity) => {
    const data = await fetchOrdersHook({ fetch, opportunityId: opportunity.Id })
    const orders = data.orders
    return orders
  }

  async function getExportData(opportunity) {
    const orders = await fetchOrders(opportunity)
    return createExport(orders)
  }

  return async (opportunity) => {
    const exportData = await getExportData(opportunity)
    const filename = `Opportunity Export - ${opportunity.Name}.csv`
    downloadFile({ 
      filename: filename,
      fileDataParts: [Papa.unparse(exportData)], 
      type: 'text/csv;charset=utf-8'
    })
  }
}

export default ({
  opportunity
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const downloadOpportunity = useDownloadOpportunity()

  const onClick = async () => {
    setIsLoading(true)
    await downloadOpportunity(opportunity)
    setIsLoading(false)
  }

  return (
    <ActionButton onClick={onClick} $disabled={isLoading}>
      <AnimatedText isAnimated={isLoading}>Export</AnimatedText>
    </ActionButton>
  )
}