import { useState } from "react"
import { createPortal } from "react-dom"
import { Button, Modal } from "@prosolve/common-components-ui"

/**
 * Extends `@prosolve/common-components-ui/Modal` with a few defaults.
 * All attributes are passed to the internal <Modal> element, so properties affecting <Modal> are valid.
 * 
 * @param {*} onClose - Called when using the default footer. If {footer} or {footerContent} is supplied, this is never called.
 * @param {*} onSubmit - Called when using the default footer. If {footer} or {footerContent} is supplied, this is never called.
 * @param {*} isOpen - Controls if the modal is open.
 * @param {*} setIsOpen - Controls if the modal is open. This is called by the default footer close button.
 * @param {*} isBusy - Indicates the modal is busy and blocks access to the default footer buttons. If {footer} or {footerContent} is supplied, this does nothing.
 * @param {*} footer - If not supplied, {footerContent} will be wrapped in a flex row div.
 * @param {*} footerContent - If not supplied, the default "cancel" "submit" buttons will be used.
 * @param {*} body - If not supplied, {bodyContent} will be wrapped in a flex column div.
 * @param {*} children - (i.e. the content within the tag) The main body content of the modal, if {body} is supplied, this is not displayed.
 */
export default (params) => {
  const { 
    onClose: onCloseParam,
    onSubmit: onSubmitParam,
    isOpen: isOpenParam,
    setIsOpen: setIsOpenParam,
    isBusy,
    //setIsBusy,
    footerContent,
    body: bodyParam,
    children
  } = params

  const [isOpen, setIsOpen] = useState(true)

  const onClose = () => {
    setIsOpen(false)
    if (setIsOpenParam) setIsOpenParam(false)
    if (onCloseParam) onCloseParam()
  }

  const onClickSubmit = () => {
    if (onSubmitParam) onSubmitParam()
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {footerContent || <>
        {
          Boolean(isBusy) &&
            <div>{'Updating...'}</div>
        }
        <Button onClick={onClose} style={{ disabled: isBusy }}>
          {`Cancel`}
        </Button>
        <Button onClick={onClickSubmit} $colored={true} style={{ disabled: isBusy }}>
          {'Submit'}
        </Button>
      </>}
    </div>
  )

  const body = (bodyParam || <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
      fontWeight: 'normal'
    }}>
      {children}
    </div>
  </>)

  const modal = (<>
    {(isOpenParam || (isOpenParam === undefined && isOpen)) && 
      <Modal title='Cancel Order' onClose={onClose} footer={footer} isAutoHeight={true} {...params}>
        {body}
      </Modal>
    }
  </>)

  return (
    createPortal(modal, document.body)
  )
}