import React from 'react';
import styled from 'styled-components';

const Card = styled.div``

const Header = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  color: #565656;
  font-size: 14px;
`

const Section = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const SectionHeader = styled.div`
  width: 80px;
  color: #080808;
  font-size: 15px;
  min-width: 80px;
`

const SectionCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 150px;
`

export default ({
  order
}) => {
  const {
    contactName: name,
    contactEmail: email,
    contactPhone: phone_number,
    address: address1,
    address2,
    city,
    state,
    zip,
  } = order;

  return (
    <Card>
      <Header>
        {'Recipient Details'}
      </Header>
      <Body>
        <Section>
          <SectionHeader>{'Name'}</SectionHeader>
          <SectionCol>
            <div>{name}</div>
            { Boolean(email) && <div style={{ color: '#0280c6' }}>{email}</div> }
            { Boolean(phone_number) && <div>{phone_number}</div> }
          </SectionCol>
        </Section>
        <Section>
          <SectionHeader>{'Address'}</SectionHeader>
          <SectionCol>
            <div>{address1 || ''}</div>
            { Boolean(address2) && <div>{address2}</div> }
            <div>{`${city ? `${city},` : ''} ${state || ''} ${zip || ''}`}</div>
          </SectionCol>
        </Section>
      </Body>
    </Card>
  )
}