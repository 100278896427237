import styled from "styled-components"

const Statuses = styled.div`
  display: flex;
  flex: 1;
`

const StatusItem = styled.div`
  color: #565656;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  border-right: solid 1px #dedede;
  border-bottom: solid 1px #dedede;
  padding: 2px 0;

  &.current-status {
    background: #f8f9fc;
    font-weight: bold;
  }

  &.last {
    border-right: none;
  }
`

const statusMapSb = {
  'ImportReview': 'Import Review',
  'Processing': 'Processing',
  'PartiallyFulfilled': 'Partially Fulfilled',
  'Fulfilled': 'Fulfilled',
  'Cancelled': 'Cancelled',
  'Exception': 'Exception'
}

/*
const statusMapSbShipment = {
  'None': 'None', 
  'Processing': 'Processing', 
  'Completed': 'Completed', 
  'Exception': 'Exception', 
  'OnHold': 'On Hold', 
  'Cancelled': 'Cancelled', 
  'CleanSweeped': 'Clean Sweeped', 
  'LabeledCreated': 'Label Created', 
  'ImportReview': 'Import Review'
}
*/

const statusMapDb = {
  'draft': 'Draft',
  'finalized': 'Finalized',
  'cancelled': 'Cancelled'
}

export default ({
  order,
  isInactive
}) => {
  const {
    source,
    status
  } = order

  const statusMap = (source === 'sb' ? statusMapSb : statusMapDb)

  return (
    <div>
      <Statuses>
        {
          isInactive ?
            <StatusItem className="current-status last">{statusMap[status]}</StatusItem> :
            <>
              {Object.entries(statusMap).map(([key, val], i, arr) => 
                <StatusItem key={key} className={(status === key && ' current-status ') + (i === arr.length - 1 && ' last ')}>
                  <span>{val}</span>
                </StatusItem>
              )}
            </>
        }
      </Statuses>
    </div>
  )
}