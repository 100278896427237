import { useState } from "react"
import styled from "styled-components"
import moment from "moment"
import LineItems from "./LineItems"
import RecipientDetails from "./RecipientDetails"
import OpportunityDetails from "./OpportunityDetails"
import ManageOpportunityButton from "./ManageOpportunityButton"
import CreateOrderButton, { CreateOrderModal } from "../Orders/CreateOrderButton/CreateOrderButton"
import { useDownloadOpportunity } from "./DownloadOpportunityButton"
import { ImportOpportunityModal } from "./ImportOpportunityButton"
import { useRefreshOpportunity } from "./RefreshOpportunityButton"
import EllipsisMenu, { EllipsisMenuItem } from "../Shared/EllipsisMenu"

const Card = styled.div`
  background: white;
  border: 1px solid #dedede;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 15%);
  margin: 2px 4px 4px 2px;

  &.closed {
    color: #080808;
  }
`

const Header = styled.div`
  background: #f8f9fc;
  border-bottom: 1px solid #dedede;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;
`

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`

export default ({
  opportunity,
  showManageButton = true,
  maxLineItemsHeight,
  onCreateOrder,
  onOpportunityUpdate,
}) => {
  const {
    Id,
    Account,
    Name,
    OpportunityLineItems,
    CloseDate, 
    Created_Date_Formula__c,
    Has_Had_QFC_Boxes_Requested__c
  } = opportunity

  const is_closed = (!!CloseDate)
  const maxLineItemsStyles = (maxLineItemsHeight ? { maxHeight: maxLineItemsHeight, overflow: 'hidden auto' } : {})

  const [isCreateOrderQfcOpen, setIsCreateOrderQfcOpen] = useState(false)
  const [isImportOppOpen, setIsImportOppOpen] = useState(false)

  const downloadOpportunity = useDownloadOpportunity()
  const refreshOpportunityHook = useRefreshOpportunity()

  const refreshOpportunity = async () => {
    await refreshOpportunityHook(Id)
    onOpportunityUpdate?.(opportunity)
  }

  const formatDate = () => {
    const displayDate = CloseDate || Created_Date_Formula__c
    return displayDate ? `${CloseDate ? 'close date' : 'created on'} ${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found'
  }

  return (
    
    <Card className={is_closed && 'closed'}>
      <Header>
        <HeaderRow>
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: '16px', marginRight: '5px' }}>{Name ? Name : 'No Account'}</div>
            <div style={{ fontStyle: 'italic', fontWeight: 'normal', color: (!!is_closed ? '#565656' : 'inherit') }}>{formatDate()}</div>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            {showManageButton &&
              <ManageOpportunityButton opportunity={opportunity} />
            }
            {!showManageButton && <>
              <CreateOrderButton opportunityId={opportunity.Id} updateOrderData={onCreateOrder} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EllipsisMenu>
                  {!Has_Had_QFC_Boxes_Requested__c && 
                    <EllipsisMenuItem name="Training" onClick={() => setIsCreateOrderQfcOpen(true)} />
                  }
                  <EllipsisMenuItem name="Export" onClick={() => downloadOpportunity(opportunity)} />
                  <EllipsisMenuItem name="Import" onClick={() => setIsImportOppOpen(true)} />
                  <EllipsisMenuItem name="Refresh" onClick={() => refreshOpportunity(opportunity.Id)} />
                </EllipsisMenu>
                {isImportOppOpen &&
                  <ImportOpportunityModal onClose={() => setIsImportOppOpen(false)} opportunity={opportunity} onCreateOrders={onCreateOrder} />
                }
                {isCreateOrderQfcOpen && 
                  <CreateOrderModal
                    onClose={() => setIsCreateOrderQfcOpen(false)} 
                    opportunityId={opportunity.Id} 
                    updateOrderData={onCreateOrder} 
                    isOpportunityQfc={true} 
                  />
                }
              </div>
            </>}
          </div>
        </HeaderRow>
      </Header>
      
      <Body style={{ ...maxLineItemsStyles }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px', fontSize: '14px' }}>
          <div style={{ display: 'none' }}><OpportunityDetails opportunity={opportunity} /></div>
          <RecipientDetails account={Account} />
        </div>
        <div style={{ flex: 1 }}>
          <LineItems lineItems={OpportunityLineItems?.records} />
        </div>
      </Body>
    </Card>
  )
}