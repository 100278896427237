import styled from "styled-components"
import moment from "moment"
import CancelWROButton from "./CancelWROButton"
import CreateWROButton from "./CreateWROButton"
import ViewWROExternalButton from "./ViewWROExternalButton"

const Card = styled.div`
  background: white;
  border: 1px solid #dedede;
  border-radius: 5px;
  color: #080808;
  margin-top: 20px;
  position: relative;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 15%);
  margin: 2px 4px 4px 2px;
`

const Header = styled.div`
  background: #f8f9fc;
  border-bottom: 1px solid #dedede;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;

  .inactive & {
    color: gray;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`

const Statuses = styled.div`
  display: flex;
  flex: 1;
`

const StatusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: #565656;
  border-right: solid 1px #dedede;
  border-bottom: solid 1px #dedede;
  padding: 2px 0;
  
  &.current-status {
    background: #f8f9fc;
    font-weight: bold;
  }

  &.last {
    border-right: none;
  }
`

const WROTable = styled.table`
  & {
    width: 100%
  }

  * th {
    text-align: left
  }
`

const statusMap = {
  toDisplay: {
    'Awaiting': 'Awaiting Arrival',
    'PartiallyArrived': 'Hub',
    'Arrived': 'Hub',
    'Processing': 'Hub',
    'PartiallyArrivedAtHub': 'Hub',
    'ArrivedAtHub': 'Hub',
    'ProcessingAtHub': 'Hub',
    'InternalTransfer': 'Internal Transfer',
    //Desination FC has 3 statuses not in API?
    'Completed': 'Complete',
    //'Cancelled': 'Cancelled',
    //'Incomplete': 'Exception',
  },
  display: {
    'Awaiting Arrival': 1,
    'Hub': 1,
    'Internal Transfer': 1,
    'Complete': 1
  }
}

export default ({
  wro,
  updateWROData
}) => {
  const {
    id,
    purchase_order_number,
    insert_date,
    fulfillment_center,
    status
  } = wro

  const is_active = (status !== 'Cancelled')

  const formatDate = () => {
    const displayDate = insert_date
    return displayDate ? `${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found'
  }

  return (
    
    <Card className={!is_active && 'inactive'}>
      <Header>
        <HeaderRow>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>WRO #{id} - {status}</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <ViewWROExternalButton wro={wro} />
            <CreateWROButton wro={wro} />
            {is_active && 
              <CancelWROButton wro={wro} updateWROData={updateWROData} />
            }
          </div>
        </HeaderRow>
      </Header>
      <Statuses>
        {Object.entries(statusMap.display).map(([key], i, arr) => 
          <StatusItem key={key} className={(statusMap.toDisplay[status] === key && ' current-status ') + (i === arr.length - 1 && ' last ')}>
            <span>{key}</span>
          </StatusItem>
        )}
      </Statuses>
      
      <Body>
        <div style={{ flex: 1 }}>
          <WROTable>
            <thead>
              <tr>
                <th>ID</th>
                <th>PO #</th>
                <th>Creation Date</th>
                <th>Fulfillment Center</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{id}</td>
                <td>{purchase_order_number}</td>
                <td>{formatDate()}</td>
                <td>{fulfillment_center.name}</td>
                <td>{status}</td>
              </tr>
            </tbody>
          </WROTable>
        </div>
      </Body>
    </Card>
  )
}