import React, { useState, useEffect } from 'react';
import DownloadCsvView from './DownloadCsvView';
import DataExplorerView from './DataExplorerView/DataExplorerView';
import useFetch from '../../hooks/useFetch';
import Tabs from '../../sharedComponents/Tabs';

export default () => {
  const [isLoading, setIsLoading] = useState(true)
  const [assessmentNames, setAssessmentNames] = useState([]);

  const fetch = useFetch();

  const fetchAssessmentNames = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`assessment/list`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAssessmentNames([])
      } else {
        setAssessmentNames(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAssessmentNames([])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAssessmentNames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabs = ['Data Explorer', 'Download CSV']

  const [selectedTab, setSelectedTab] = useState('Data Explorer')

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{ marginTop: '10px' }}>
        <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      {
          selectedTab === 'Download CSV' ?
          <DownloadCsvView isLoading={isLoading} assessmentNames={assessmentNames} />
          :
          selectedTab === 'Data Explorer' ?
          <DataExplorerView />
          :
          <></>
        }
    </div>
  )
}