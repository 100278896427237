import useFetch from "../../hooks/useFetch"

export default () => {
  const fetch = useFetch()
  
  return {
    createOrder: async (data, isEdit = false) => {
      try {
        // Should we have a single endpoint for both create and update instead?
        const urlEndpoint = `shipping/order/${isEdit ? 'edit' : 'create'}`
        const response = await fetch(urlEndpoint, { 
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        })
        
        const jsonData = await response.json();
        if (jsonData.error) return jsonData
        
        if (!isEdit) jsonData.shouldRefreshImmediately = true
        return jsonData
      } catch (e) {
        return { error: e }
      }
    }
  }
}