import useFetch from "../../hooks/useFetch"

export default () => {
  const fetch = useFetch()

  return {
    fetchProducts: async () => {
      const response = await fetch(`shipping/product`)
      const jsonData = await response.json();
      if (jsonData.error) {
        throw new Error(jsonData.error)
      }
      return jsonData
    }
  }
}