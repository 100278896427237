import React, { useState, useEffect } from 'react'
import { SearchDropdown } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'
import useShipmentSnackbar from './useShipmentSnackbar';
import OptionalFormFieldWrapper from './OptionalFormFieldWrapper';

export default ({
  accountId,
  isShowLabel = true,
  value: defaultValue,
  onChange: onChangeParam
}) => {
  const fetch = useFetch();

  const shipmentSnackbar = useShipmentSnackbar()

  const [value, setValue] = useState(defaultValue)
  const [search, setSearch] = useState(defaultValue)
  const [isLoading, setIsLoading] = useState(false)
  const [implementations, setImplementations] = useState([])

  const options = implementations
    .filter(impl => 
      ((search || '').length === 0 ? true : (
        impl.Name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        impl.Id.toLowerCase().indexOf(search.toLowerCase()) > -1
      ))
    )
    .map(impl => ({
      text: impl.Name,
      value: impl.Id,
    }))

  const fetchImplementations = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`accounts/implementations?accountId=${accountId}`, { method: 'GET' })
      const jsonData = await response.json();
      const newData = jsonData.error ? [] : jsonData
      //console.log('implementations', jsonData)
      setImplementations(newData)
      return newData
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting implementations', severity: 'failure' })
      setImplementations([])
    } finally {
      setIsLoading(false);
    }
    return []
  }

  const onChange = (val) => {
    setValue(val)
    if (onChangeParam) onChangeParam(val)
  }

  useEffect(() => {
    async function fetchImpl() {
      if (accountId) {
        const implementations = await fetchImplementations();
        
        if (implementations.length > 0) {
          // How to select the first one?
        }
      } else {
        setImplementations([])
      }
    }
    fetchImpl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <OptionalFormFieldWrapper isEnabled={isShowLabel} label={'Implementation'}>
        <SearchDropdown
          value={value}
          options={options}
          onChange={(val) => onChange(val)}
          onSearch={(val) => setSearch(val)}
          isLoading={isLoading}
          maxHeight='200px'
          width='350px'
        />
      </OptionalFormFieldWrapper>
    </div>
  );
}