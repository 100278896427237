import useFetch from "../../hooks/useFetch"

export default () => {
  const fetch = useFetch()

  return {
    fetchEmailTemplates: async (options) => {
      try {
        const params = new URLSearchParams(Object.entries(options || {}).filter(e => e[1] !== undefined))
        const response = await fetch(`shipping/email/templates${params.size > 0 ? `?${params.toString()}` : ''}`, { method: 'GET' })
        const data = await response.json();
        return data
      } catch (e) {
        console.error(e)
        return Promise.reject(e)
      }
    },

    fetchApplyTemplate: async (options) => {
      try {
        const response = await fetch('shipping/email/apply-template', { 
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(options)
        })
        const jsonData = await response.json();
        if (jsonData.error) {
          return Promise.reject(jsonData.error)
        } else {
          return Promise.resolve(jsonData)
        }
      } catch (e) {
        console.error(e)
        return Promise.reject(e)
      }
    }
  }
}