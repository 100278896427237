import ProductDetails from "./ProductDetails"

export default ({
  products,
  updateProductData
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '10px' }}>
      {products.map(i => {
        return <ProductDetails key={i.id} product={i} updateProductData={updateProductData} />
      })}
    </div>
  )
}