import Cookies from "js-cookie"
import { arrayToMap } from "../../../utils"
import useFetch from "../../../hooks/useFetch"

export default () => {
  const fetch = useFetch()

  const fetchOpportunityOrderDetails = async (opportunityId, isPhysicalLineItemsOnly = true) => {
    try {
      const result = await fetch(`accounts/opportunityOrderDetails?opportunityId=${opportunityId}&isPhysicalLineItemsOnly=${isPhysicalLineItemsOnly}`)
      const resultJson = await result.json()
      return resultJson?.[0]
    } catch (e) {
      console.log(e)
    }
  }

  // TODO Duplicate from shipping dropdown
  const fetchProductsData = async () => {
    try {
      const response = await fetch(`shipping/product`)
      const jsonData = await response.json();
      const finalJsonData = jsonData.error ? [] : jsonData
      //setProductsData(finalJsonData)
      return finalJsonData
    } catch (e) {
      console.log(e)
      //setProductsData([])
    }
    return []
  }

  // TODO Duplicate from Implementation dropdown
  const fetchImplementations = async (accountId) => {
    try {
      const response = await fetch(`accounts/implementations?accountId=${accountId}`, { method: 'GET' })
      const jsonData = await response.json();
      const finalJsonData = jsonData.error ? [] : jsonData
      //setImplementations(finalJsonData)
      return finalJsonData
    } catch (e) {
      console.log(e)
      //setImplementations([])
    }
    return []
  }

  const exports = {
    buildOrderTestValues: () => {
      return { 
        accountId: '0015000000uUHFYAA4', // Prosolve
        implementationId: 'a3NJx000003x7gTMAQ', // Y24-8/12 - ProSolve Sales Team 🎓 SLED
        opportunityId: '0060z00001xO3TVAA0', // GaETC (Prosolve)
        products: [
          { id: 14324038, sku: 'SLED-BK', quantity: 1 },
          { id: 14324039, sku: 'QSTAR-BK-S1', quantity: 2 }
        ],
        contactName: 'Bill Tester',
        contactEmail: 'bt@prosolve.com',
        contactPhone: '213-733-7890',
        address: '9521 Cobblestone Street',
        city: 'Pacoima',
        state: 'CA',
        zip: '91331',
        requester: 'Paul Dev'
      }
    },

    buildOrderFromOppBase: async (opportunityId, accountId, oppLineRecFilter) => {
      // TODO should share product data with productDropdown, otherwise we make a call for EACH item type shipped
      const opportunityData = await fetchOpportunityOrderDetails(opportunityId, false)
      const implementationData = await fetchImplementations(opportunityData.Account.Id || accountId)
      const productsData = await fetchProductsData()
      //console.log('productsData', productsData)

      const productsDataByName = arrayToMap(productsData, 'reference_id')

      return { 
        accountId: opportunityData.Account.Id,
        implementationId: (implementationData?.[0]?.Id || undefined),
        opportunityId: opportunityData.Id,
        products: opportunityData.OpportunityLineItems?.records?.filter(oppLineRecFilter).map(o => {
          var productData = productsDataByName[o.PricebookEntry.Product2.Shipbob_SKU_Code__c]
          return {
            name: o.PricebookEntry.Product2.Name, // currently unused, it's recorded here for debugging purposes
            id: productData?.id, // currently unused, it doesn't appear that ShipBob accepts productId
            sku: productData?.reference_id || '',
            quantity: o.Quantity || 1 // should we use Units_Remaining_to_Ship__c instead?
          }
        }) || [],
        contactName: opportunityData.Account.Shipping_Contact_Name__c,
        contactEmail: opportunityData.Account.Shipping_Contact_Email__c,
        contactPhone: opportunityData.Account.Shipping_Contact_Phone_Number__c,
        address: opportunityData.Account.ShippingStreet || opportunityData.Account.BillingStreet,
        city: opportunityData.Account.ShippingCity || opportunityData.Account.BillingCity,
        state: opportunityData.Account.ShippingState || opportunityData.Account.BillingState,
        zip: opportunityData.Account.ShippingPostalCode || opportunityData.Account.BillingPostalCode,
        requester: Cookies.get('name')
      }
    },

    buildOrderFromOpportunity: async (opportunityId, accountId) => {
      return await exports.buildOrderFromOppBase(opportunityId, accountId, (o) => (o.SKU_Type__c === 'Physical'))
    },

    buildOrderForOpportunityQFC: async (opportunityId, accountId) => {
      return await exports.buildOrderFromOppBase(opportunityId, accountId, (o) => (o.SKU_Type__c === 'Service' && o.PricebookEntry.Product2.Shipbob_SKU_Code__c))
    },

    updateOrderFromContact: (orderInitData, contact) => {
      return {
        ...orderInitData,
        contactName: contact.Name,
        contactEmail: contact.Email,
        contactPhone: contact.Phone,
        address: contact.MailingStreet,
        city: contact.MailingCity,
        state: contact.MailingState,
        zip: contact.MailingPostalCode,
      }
    }
  }

  return exports
}