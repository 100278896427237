import { useEffect, useState } from "react";
import { FadeInWrapper, Footer, LoadingIcon, Main, MainContent, Section } from "@prosolve/common-components-ui";
import useIsMobileView from "../../hooks/useIsMobileView";
import useFetch from "../../hooks/useFetch";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import useFetchOrders from "../Shared/useFetchOrders";
import OrderList from "./OrderList";
import CreateOrderButton from "./CreateOrderButton/CreateOrderButton";
import RefreshOrderButton from "./RefreshOrderButton";
import OrderFilters from "./OrderFilters";

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const isMobileView = useIsMobileView();
  const fetch = useFetch()
  const shipmentSnackbar = useShipmentSnackbar()
  const { fetchOrders: fetchOrdersHook } = useFetchOrders()

  const [isLoading, setIsLoading] = useState(true)
  const [isOrderDataAvailable, setIsOrderDataAvailable] = useState(false)
  const [orders, setOrders] = useState([])
  const [filteredOrders, setFilteredOrders] = useState(orders)

  const fetchOrders = async () => {
    setIsLoading(true)
    try {
      const data = await fetchOrdersHook({ fetch })
      const orders = data.orders
      setOrders(data.error ? [] : orders)
      if (data.error) {
        shipmentSnackbar.newMessage({ message: 'Error getting orders', severity: 'failure' })
      }
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting orders', severity: 'failure' })
    } finally {
      setIsOrderDataAvailable(true)
    }
  }

  const onRefreshStart = () => {
    const confirm = window.confirm('Are you sure you want to refresh the cache?')
    if (confirm) {
      setIsLoading(true)
    }
    return confirm
  }

  const onRefreshComplete = () => {
    fetchOrders()
  }

  const onFilteredOrdersChange = (newFilteredOrders) => {
    setFilteredOrders(newFilteredOrders)
    if (isOrderDataAvailable) setIsLoading(false)
  }

  const updateOrderData = (data, isCreate) => {
    //console.log('updateOrderData', data, isCreate)
    if (!!isCreate) {
      const updatedOrders = [ { data }, ...orders ]
      setOrders(updatedOrders)
    } else {
      const orderIndex = orders.findIndex(p => p.data?._id === data._id)
      const updatedOrders = [ ...orders ]
      updatedOrders[orderIndex].data = data
      setOrders(updatedOrders)
    }
  }

  useEffect(() => {
    fetchOrders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main $isMobileView={isMobileView} $backgroundColor={'inherit'} style={{ padding: '10px' }}>
      <MainContent $isMobileView={isMobileView}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            fontSize: '28px',
            fontWeight: 'bold'
          }}>
            {'Manage Orders'}
            <span style={{ paddingLeft: '10px', fontSize: '1rem' }}>
              <RefreshOrderButton text='Refresh All Orders' onRefreshStart={onRefreshStart} onRefreshComplete={onRefreshComplete} />
            </span>
          </div>
        </div>
        <div style={{ color: 'gray', fontSize: '0.8em', marginTop: '5px', marginBottom: '20px' }}>
          {'(Up to 100 entries displayed at a time)'}
        </div>
        <div>
          <OrderFilters orders={orders} onFilteredOrdersChange={onFilteredOrdersChange} />
        </div>
        <div style={{ paddingBottom: '20px' }}>
          <CreateOrderButton updateOrderData={updateOrderData} />
        </div>
        <div style={{ padding: '0' }}>
        { 
          isLoading 
          ?
          <Section style={{ background: 'inherit', height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          !filteredOrders?.length ?
          <Section style={{ background: 'inherit', height: '400px' }}>
            Unable to find order data
          </Section>
          :
          <FadeInWrapper>
            <Section style={{ background: 'inherit', padding: '0px' }}>
              <OrderList orders={filteredOrders} />
            </Section>
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}