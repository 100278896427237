import { useEffect, useState } from 'react'
import { SearchDropdown } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'
import useShipmentSnackbar from './useShipmentSnackbar';
import OptionalFormFieldWrapper from './OptionalFormFieldWrapper';

export default ({
  value: defaultValue,
  onChange: onChangeParam = () => {},
  isShowNoAccountOption = false
}) => {
  const fetch = useFetch()

  const [value, setValue] = useState(defaultValue)
  const [searchVal, setSearchVal] = useState(defaultValue)
  const [isLoading, setIsLoading] = useState(false)
  const [accounts, setAccounts] = useState()

  const shipmentSnackbar = useShipmentSnackbar()

  const filteredOptions = accounts?.map(a => {
    return {
      text: a.Name,
      value: a.Id,
    }
  }) || []
  if (filteredOptions.length > 0) {
    if (isShowNoAccountOption) {
      filteredOptions.unshift({
        text: '[No Account]',
        value: '[noaccount]'
      })
    }
    filteredOptions.unshift({
      text: '',
      value: ''
    })
  }

  const fetchAccounts = async () => {
    try {
      setIsLoading(true);
      // Salesforce limits return to 200, and we have more than 200 accounts. 
      // The dropdown needs the selected option available, or it displays the value instead the much more desired account name.
      const response = await fetch(`accounts${searchVal ? `?nameOrId=${searchVal}` : ''}`, { method: 'GET' })
      const jsonData = await response.json();
      setAccounts(jsonData.error ? [] : jsonData);
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting accounts', severity: 'failure' })
      setAccounts([])
      setIsLoading(false);
    }
  }

  const onChange = (val) => {
    setValue(val)
    onChangeParam(val)
  }

  useEffect(() => {
    fetchAccounts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal])

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <OptionalFormFieldWrapper isEnabled={true} label={'Account'}>
        <SearchDropdown
          value={value}
          options={filteredOptions}
          onChange={(val) => onChange(val) }
          onSearch={(val) => setSearchVal(val)}
          isLoading={isLoading}
          maxHeight='200px'
          width='350px'
        />
      </OptionalFormFieldWrapper>
    </div>
  )
}