import ShipmentDetails from "./ShipmentDetails"

export default ({
  shipments,
  updateSbOrderData
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {shipments?.map(shipment => 
        <ShipmentDetails key={shipment.id} shipment={shipment} updateSbOrderData={updateSbOrderData} />
      )}
    </div>
  )
}