import React from 'react';
import ProductDetails from './ProductDetails';

export default ({
  products = [],
  isShowHeader = true
}) => {
  return (<>
    {isShowHeader && 
      <div style={{
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1 }}>
          {`Products`}
        </div>
      </div>
    }
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
      {products.map((product, index) => {
          return <ProductDetails key={product.sku || index} product={product} />
      })}
    </div>
  </>)
}