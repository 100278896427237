import { Button } from "@prosolve/common-components-ui"
import styled from "styled-components"

export default styled(Button).attrs({ 
  $colored: true, 
  style: { 
    height: '25px', 
    borderRadius: '20px',
    textAlign: 'center'
  } 
})`
  text-transform: uppercase;

  &:active {
    color: rgba(68, 172, 225, 0.7);
  }
`