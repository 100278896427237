import { FaBriefcase } from "react-icons/fa";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FadeInWrapper, LoadingIcon, Section } from "@prosolve/common-components-ui";
import OpportunityCardOppItem from "./OpportunityCardOppItem";

const Card = styled.div`
  border: solid 1px rgb(222, 222, 222);
  border-bottom: 3px solid rgb(2, 128, 198);
  border-radius: 5px;
  background: white;
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 300px;
  box-shadow: 2px 4px 6px rgb(0 0 0 / 15%);
  margin: 2px 4px 6px 2px;
  padding-bottom: 20px;
`

const Title = styled.div`
  alignItems: center;
  display: flex;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    color: rgb(2, 128, 198);
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: none;
`

export default ({
  title,
  opportunities,
  reportType,
  isLoading
}) => {
  const navigate = useNavigate()

  const onReportTypeClick = () => {
    navigate(`/shipping/opportunities?reportType=${reportType}`)
  }

  return (
    <Card>
      <Title onClick={onReportTypeClick}>
        {title}
      </Title>
      <Body>
      { 
        isLoading 
        ?
        <Section style={{ background: 'inherit', padding: '0 0 20px 0', alignItems: 'center', margin: 'auto' }}>
          <LoadingIcon />
        </Section>
        :
        !opportunities.length ?
        <Section style={{ background: 'inherit', padding: '0 0 20px 0', margin: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', opacity: 0.1 }}>
            <div style={{ fontSize: '100px', lineHeight: 0 }}><FaBriefcase /></div>
            <div style={{ fontSize: '24px' }}>No Opportunities</div>
          </div>
        </Section>
        :
        <FadeInWrapper>
          <Section style={{ background: 'inherit', padding: '0px' }}>
            {opportunities.map(opp => {
              return <OpportunityCardOppItem key={opp.Id} opportunity={opp} />
            })}
          </Section>
        </FadeInWrapper>
      }
      </Body>
    </Card>
  )
}