import styled from "styled-components"
import ImagePlaceholder from '../../images/image-placeholder.jpg'
import AddEditProductButton from "./AddEditProductButton"
import ViewProductExternalButton from "./ViewProductExternalButton"

const Card = styled.div`
  background: white;
  border: 1px solid #dedede;
  border-radius: 5px;
  color: #080808;
  position: relative;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 15%);
  margin: 2px 4px 4px 2px;
`

const Header = styled.div`
  background: #f8f9fc;
  border-bottom: 1px solid #dedede;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;

  .inactive & {
    color: gray;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`

const ProductTable = styled.table`
  & {
    width: 100%
  }

  * th {
    text-align: left
  }
`

export default ({
  product,
  updateProductData
}) => {
  const {
    name,
    reference_id,
    sku,
    channel,
    total_committed_quantity,
    total_fulfillable_quantity,
    total_onhand_quantity,
    inventoryData
  } = product

  // There is no active property returned. However, all inactive items seem to have a channel id of 0.
  const is_active = (channel.id !== 0)
  const img = inventoryData?.Product_Image__c || ImagePlaceholder

  return (
    
    <Card className={!is_active && 'inactive'}>
      <Header>
        <HeaderRow>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>{product.name}</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <ViewProductExternalButton product={product} />
            <AddEditProductButton product={product} updateProductData={updateProductData} />
          </div>
        </HeaderRow>
      </Header>
      
      <Body>
        <div style={{ flex: 1 }}>
          <ProductTable>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Reference Id</th>
                <th>SKU</th>
                <th>On&nbsp;Hand</th>
                <th>Committed</th>
                <th>Fulfillable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><img src={img} style={{ height: '70px', marginRight: '10px' }} alt="" /></td>
                <td>{name}</td>
                <td>{reference_id}</td>
                <td>{sku}</td>
                <td>{total_onhand_quantity}</td>
                <td>{total_committed_quantity}</td>
                <td>{total_fulfillable_quantity}</td>
              </tr>
            </tbody>
          </ProductTable>
        </div>
      </Body>
    </Card>
  )
}