import React from 'react';
import styled from 'styled-components';
import ImagePlaceholder from '../../images/image-placeholder.jpg'

const Card = styled.div`
  display: flex;
  color: #565656;
  font-size: 14px;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  margin-top: 2px;
  flex: 1;
`

const Row = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 20px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ({
  lineItem
}) => {
  const {
    Quantity,
    Units_Shipped__c,
    Units_Requested__c,
    PricebookEntry: {
      Product2: {
        Name
      }
    },
    image
  } = lineItem

  const imageSrc = image || ImagePlaceholder;

  return (
    <Card>
      <img src={imageSrc} style={{ height: '70px', marginRight: '10px' }} alt=""/>
      <Details>
        <Row>
          <Column style={{ flex: '1', alignItems: 'start' }}>
            <div style={{ color: '#565656', fontSize: '16px', minWidth: '100px' }}>{Name}</div>
          </Column>
          <Column>
            <div style={{ color: 'rgb(2, 128, 198)', fontSize: '24px' }}>{Quantity || 0}</div>
            <div style={{ color: 'gray' }}>Purchased</div>
          </Column>
          <Column>
            <div style={{ color: 'rgb(2, 128, 198)', fontSize: '24px' }}>{Units_Requested__c || 0}</div>
            <div style={{ color: 'gray' }}>Requested</div>
          </Column>
          <Column>
            <div style={{ color: 'rgb(2, 128, 198)', fontSize: '24px' }}>{Units_Shipped__c || 0}</div>
            <div style={{ color: 'gray' }}>Shipped</div>
          </Column>
        </Row>
      </Details>
    </Card>
  )
}