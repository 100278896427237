import React, { useState } from "react";
import { Modal, Button } from '@prosolve/common-components-ui'
import useFetch from '../../../hooks/useFetch';
import { checkHasPermissionShipmentTrackingEdit } from "../../../utils";
import ActionButton from "../../Shared/ActionButton";
import useShipmentSnackbar from "../../Shared/useShipmentSnackbar";

export const CancelOrderModal = ({
  order,
  updateOrderData,
  onClose: onCloseParam
}) => {
  const {
    _id,
  } = order;

  const [isBusy, setIsBusy] = useState(false);
  const fetch = useFetch();

  const shipmentSnackbar = useShipmentSnackbar()

  const hasEdit = checkHasPermissionShipmentTrackingEdit()

  const cancelOrder = async () => {
    setIsBusy(true)
    try {
      const data = JSON.stringify({
        orders: [order]
      })
      const headers = {
        "Content-Type": "application/json",
      }
      const response = await fetch('shipping/order/draft/cancel', { method: 'POST', headers, body: data })

      const jsonData = await response.json();
      if (jsonData.error) {
        console.error(jsonData.error)
        shipmentSnackbar.newMessage({ message: 'Error cancelling order', severity: 'failure' })
      } else {
        updateOrderData(jsonData?.[0])
        onClose()
      }
      
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error cancelling order', severity: 'failure' })
    }
    setIsBusy(false)
  }

  const onClose = () => {
    onCloseParam?.()
  }

  const onClickSubmit = () => {
    cancelOrder()
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose} style={{ disabled: isBusy }}>
        {`Don't Cancel`}
      </Button>
      <Button onClick={onClickSubmit} $colored={true} style={{ disabled: isBusy }}>
        {'Submit'}
      </Button>
    </div>
  )

  return (<>
    {hasEdit && <>
      <Modal title='Cancel Order' onClose={onClose} footer={footer} isAutoHeight={true}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '16px',
          fontWeight: 'normal'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px'
          }}>
            {`Are you sure you want to cancel order #${_id}?`}
          </div>
        </div>
      </Modal>
    </>}
  </>)
}

export default ({
  order,
  updateOrderData
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasEdit = checkHasPermissionShipmentTrackingEdit()

  return (<>
    {hasEdit && <>
      {isOpen &&
        <CancelOrderModal onClose={() => setIsOpen(false)} order={order} updateOrderData={updateOrderData} />
      }
      <ActionButton onClick={() => setIsOpen(true)}>
        Cancel&nbsp;Order
      </ActionButton>
    </>}
  </>)
}