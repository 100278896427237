import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArchive, FaBriefcase, FaClipboard, FaDesktop, /*FaEnvelope,*/ FaInbox, FaListAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { LogoutButton } from '@prosolve/common-components-ui';
import ProsolveLogoSlim from '../images/prosolve-logo-slim.png';
import useFetch from '../hooks/useFetch';
import useIsMobileView from '../hooks/useIsMobileView';
import QuerySf from './Shared/QuerySf';
import Orders from './Orders/Orders';
import Products from './Products/Products';
import Inventory from './Inventory/Inventory';
import Dashboard from './Dashboard/Dashboard';
import WRO from './WRO/WRO';
import Opportunities from './Opportunities/Opportunities';
//import Email from './Email/Email';

const drawerWidth = 240;

const navItems = [
  { name: 'Dashboard', url: '', component: <Dashboard />, icon: <FaDesktop /> },
  { name: 'Opportunities', url: 'opportunities', component: <Opportunities />, icon: <FaBriefcase /> },
  { name: 'Orders', url: 'orders', component: <Orders />, icon: <FaClipboard /> },
  { name: 'Products', url: 'products', component: <Products />, icon: <FaListAlt /> },
  { name: 'Inventory', url: 'inventory', component: <Inventory />, icon: <FaArchive /> },
  { name: 'WROs', url: 'wros', component: <WRO />, icon: <FaInbox /> },
  //{ name: 'Email', url: 'email', component: <Email />, icon: <FaEnvelope /> }
]

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 100%;
`

/*
 colors:
  button-blue #0280c6
  background-blue: #016092?

  text-blue: #0280c6
  background-powder-blue: #f0faff

  text-gray: #707070
*/
const Aside = styled.div`
  width: calc(${drawerWidth}px - 40px); 
  padding: 20px;
  border-right: solid 1px #C2C9D1;
  background-color: white;
  color: #707070;

  .mobile & {
    padding: 0;
  }
`

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const MainTitlebar = styled.div`
  display: flex;
  align-items: center;
  color: #707070;
  height: 50px;
  width: calc(100% - 40px);
  padding: 0 20px;
  border-bottom: solid 1px #C2C9D1;
`

const MainLogo = styled.div`
  .mobile & {
    display: none;
  }
`

const MainTitle = styled.div`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
`

const MainContent = styled.div`
  flex: 1;
  width: calc(100% - 40px);
  padding: 20px;
  background: #f5f5f5;
`

const Drawer = styled.div`
  width: 100%;

  .mobile & {
    display: none;
  }
`

const DrawerExpandButton = styled.div`
  display: none;
  cursor: pointer;
  padding: 5px 10px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 24%);

  .mobile & {
    display: block;
  }
`

const DrawerPopout = styled.div`
  position: absolute;
  width: 150px;
  height: 100%;
  background: white;
  padding: 10px;
  z-index: 10;
  
  left: -200px;
  transition: left 225ms cubic-bezier(0, 0, 0.2, 1), 
    opacity 225ms cubic-bezier(0, 0, 0.2, 1);

  .mobile .popout-open & {
    left: 0;
  }
`

const DrawerPopoutBackground = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;

  .mobile .popout-open & {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
`

const DrawerItem = styled(Link)`
  display: flex;
  padding: 20px;
  border-radius: 8px;
  text-decoration: none;
  
  &:link, &:visited, &:active {
    color: #707070;
  }
  &:hover {
    color: #0280c6;
  }

  & .nav-icon {
    padding-right: 10px;
  }
`

export default () => {
  const { tab } = useParams();

  const fetch = useFetch();

  const isMobileView = useIsMobileView()

  const selectedNav = navItems.find(n => n.url === tab) || navItems[0]

  const [isDrawerPopoutOpen, setIsDrawerPopoutOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerPopoutOpen(!isDrawerPopoutOpen);
    document.body.style.overflow = (isDrawerPopoutOpen ? 'auto' : 'hidden')
  };

  const drawer = (
    <>
      {navItems.map(nav => 
        <DrawerItem 
          key={nav.name}
          to={`/shipping/${nav.url}`}
          style={{...(nav.name === selectedNav.name ? { backgroundColor: '#f0faff', color: '#0280c6' } : {})}}
        >
          <span className='nav-icon'>{nav.icon}</span> {nav.name}
        </DrawerItem>
      )}
    </>
  );

  const main = (
    <div>{selectedNav.component}</div>
  )

  return (
    <Layout className={isMobileView && 'mobile'}>
      <MainTitlebar>
        <DrawerExpandButton onClick={handleDrawerToggle}>
          ☰
        </DrawerExpandButton>
        <MainLogo>
          <img src={ProsolveLogoSlim} style={{ height: '50px', position: 'relative', top: '3px' }} alt="prosolveLogo"/>
        </MainLogo>
        <MainTitle>
          Shipping Portal
        </MainTitle>
        <div>
          <LogoutButton fetch={fetch} stayOnPath={true} />
        </div>
      </MainTitlebar>
      
      <MainWrapper>
        <Aside className={isDrawerPopoutOpen && 'popout-open'}>
          <DrawerPopout>
            {drawer}
          </DrawerPopout>
          <DrawerPopoutBackground onClick={handleDrawerToggle} />
          <Drawer>
            {drawer}
          </Drawer>
        </Aside>

        <MainContent>
          {main}
          <QuerySf />
        </MainContent>
      </MainWrapper>
    </Layout>
  );
}