import { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import { FaExclamationTriangle } from "react-icons/fa"
import { LoadingIcon } from "@prosolve/common-components-ui"
import Shipments from "../OrderDetails/Shipments"
import StatusBar from "./StatusBar"
import RecipientDraftDetails from "./RecipientDetails"
import Products from "./Products"
import { FinalizeOrderModal } from "./FinalizeOrderButton"
import { useRefreshOrder } from "../RefreshOrderButton"
import { CancelOrderModal } from "./CancelOrderButton"
import CreateOrderButton from "../CreateOrderButton/CreateOrderButton"
import { OrderNotificationTestModal } from "./OrderNotificationTestButton"
import EllipsisMenu, { EllipsisMenuItem } from "../../Shared/EllipsisMenu"

const Card = styled.div`
  background: white;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-top: 20px;
  position: relative;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 15%);
  margin: 2px 4px 4px 2px;
`

const Header = styled.div`
  background: #f8f9fc;
  border-bottom: 1px solid #dedede;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;

  .inactive & {
    color: gray;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`

const Loading = styled.div`
  align-items: center;
  background: rgba(255,255,255, 0.8);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
`

export default ({
  orderEx,
  updateOrderData,
  updateSbOrderData,
}) => {
  const orderInitial = (orderEx.dataSb || orderEx.data)
  //console.log('order', order)

  const [order, setOrder] = useState(orderInitial)
  const [isCancelOrderOpen, setIsCancelOrderOpen] = useState(false)
  const [isFinalizeOrderOpen, setIsFinalizeOrderOpen] = useState(false)
  const [isOrderNotificationOpen, setIsOrderNotificationOpen] = useState(false)

  const refreshOrder = useRefreshOrder()

  const {
    status,
    project,
    created_date,
    prosolveAltered,
    is_qfc
  } = order
  const id = (order._id || order.id)

  const isSourceSb = (order.source === 'sb')
  const isException = (status === 'Exception')
  const isActive = (
    (isSourceSb && (status !== 'Cancelled')) ||
    (!isSourceSb && (status !== 'cancelled' && status !== 'finalized'))
  )
  const canFinalize = (status === 'draft')

  const [isBusy, setIsBusy] = useState()

  const formatDate = () => {
    const displayDate = created_date
    return displayDate ? `on ${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found'
  }

  const onOrderChange = async (data) => {
    await refreshOrderWithLoading()
    if (updateOrderData) updateOrderData(data)
  }

  const onSbOrderChange = async (data, dataSb) => {
    await refreshOrderWithLoading()
    if(updateSbOrderData) updateSbOrderData(data, dataSb)
  }

  const onClickRefresh = () => {
    // We don't want onClickRefresh to be async, because that will open a full screen loading icon
    refreshOrderWithLoading()
  }

  const refreshOrderWithLoading = async () => {
    setIsBusy(true)
    const orders = await refreshOrder(order)
    //console.log('onClickRefresh', '.orders', orders)
    if (orders && !orders?.[0]?.error) {
      const updatedOrderEx = orders?.[0]
      setOrder(updatedOrderEx.dataSb || updatedOrderEx.data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (order.shouldRefreshImmediately) {
      refreshOrderWithLoading()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.shouldRefreshImmediately])

  return (
    <Card className={!isActive && 'inactive'}>
      {isBusy && (
        <Loading>
          <LoadingIcon />
        </Loading>
      )}
      <Header>
        <HeaderRow>
          {(isException || prosolveAltered) && 
            <div style={{ cursor: (prosolveAltered ? 'help' : ''), marginRight: '15px' }}>
              <FaExclamationTriangle 
                title={(prosolveAltered ? 'This record has been altered for testing purposes and may not represent exactly how it is presented in production' : '')} 
                style={{ color: (isException ? '#ef0c0c' : '#FEB800'), fontSize: '20px' }} 
              />
            </div>
          }
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: '16px', marginRight: '5px' }}>{project ? project : 'No Account'} - {isSourceSb ? '' : 'Draft'} Order #{id} {is_qfc ? '(Training)' : ''}</div>
            <div style={{ fontStyle: 'italic', fontWeight: 'normal', color: (!!isActive ? '#565656' : 'inherit') }}>{formatDate()}</div>
            <div style={{ display: 'none'}}>{status}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
            {isSourceSb ? 
              <></>
                :
              <>
                {/* Edit order */}
                {id && canFinalize &&
                  <CreateOrderButton order={order} updateOrderData={onOrderChange} />
                }
              </>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EllipsisMenu>
                {isSourceSb ? 
                  <>
                    <EllipsisMenuItem name="Email" onClick={() => setIsOrderNotificationOpen(true)} />
                  </>
                    :
                  <>
                    {id && isActive &&
                      <EllipsisMenuItem name="Cancel" onClick={() => setIsCancelOrderOpen(true)} />
                    }
                    {id && canFinalize &&
                      <EllipsisMenuItem name="Finalize" onClick={() => setIsFinalizeOrderOpen(true)} />
                    }
                    {id && 
                      <EllipsisMenuItem name="Email" onClick={() => setIsOrderNotificationOpen(true)} />
                    }
                  </>
                }
                {id && 
                  <EllipsisMenuItem name="Refresh" onClick={onClickRefresh} />
                }
              </EllipsisMenu>
              {isCancelOrderOpen && 
                <CancelOrderModal onClose={() => setIsCancelOrderOpen(false)} order={order} updateOrderData={onOrderChange} />
              }
              {isFinalizeOrderOpen &&
                <FinalizeOrderModal onClose={() => setIsFinalizeOrderOpen(false)} order={order} updateSbOrderData={onSbOrderChange} />
              }
              {isOrderNotificationOpen &&
                <OrderNotificationTestModal onClose={() => setIsOrderNotificationOpen(false)} order={orderEx.data} />
              }
            </div>
          </div>
        </HeaderRow>
      </Header>
      <StatusBar order={order} isInactive={!isActive} />
      
      <Body>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
          <RecipientDraftDetails order={order} />
        </div>
        <div style={{ flex: 1 }}>
          {/* Drafts only have products. Once finalized, the products are broken into shipments */}
          {
            order.shipments ? 
              <Shipments shipments={order.shipments} updateSbOrderData={onSbOrderChange} /> :

            order.products &&
              <Products products={order.products} />
          }
        </div>
      </Body>
    </Card>
  )
}