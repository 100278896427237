import { useState } from "react"
import { createPortal } from "react-dom"
import Cookies from "js-cookie"
import styled from "styled-components"
import { Button, Modal, SelectDropdown } from "@prosolve/common-components-ui"
import ActionButton from "../../Shared/ActionButton"
import useOrderNotification from "../../Shared/useOrderNotification"
import useShipmentSnackbar from "../../Shared/useShipmentSnackbar"
import EmailTemplate from "../../Email/EmailTemplate"

const SectionRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 15px;
  //border: solid 1px rgb(2 128 198 / 50%);
  //border: solid 1px rgb(0 0 0 / 10%);
  border-top: solid 3px rgb(2, 128, 198);
  border-left: solid 1px rgb(0 0 0 / 10%);
  border-right: solid 1px rgb(0 0 0 / 10%);
  border-bottom: solid 1px rgb(0 0 0 / 10%);
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  background: rgba(0, 0, 0, 0.01);
`

const SectionHeader = styled.div`
  color: gray;

  //border-bottom: solid 1px #0280c6;
`

export const OrderNotificationTestModal = ({
  order,
  onClose
}) => {
  const { sendOrderNotification, NotificationTypes } = useOrderNotification()
  const { newMessage } = useShipmentSnackbar()

  const email = Cookies.get('email')

  const [isBusy, setIsBusy] = useState(false)
  const [selNotificationType, setSelNotificationType] = useState(NotificationTypes.OrderCreated.emailTemplateId)

  const selectOptions = Object.entries(NotificationTypes).map(e => {
    const [, val] = e
    return {
      value: val.emailTemplateId,
      text: val.name
    }
  })
  
  const fetchSendOrderNotification = async (notificationType) => {
    try {
      setIsBusy(true)

      if (!email) throw new Error('cannot find email address')
      
      await sendOrderNotification({ 
        email: email,
        orderId: order._id,
        notificationType: notificationType,
        isTestEmail: true
      })
      newMessage({ message: `Sent test email to ${email}`, severity: 'success' })
    } catch (e) {
      console.log(e)
      newMessage({ message: `Failed to send email: ` + e, severity: 'failure' })
    } finally {
      setIsBusy(false)
    }
  }

  const onNotificationClick = () => {
    fetchSendOrderNotification(selNotificationType)
  }

  const onModalCloseClick = () => {
    onClose?.()
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      <Button onClick={onModalCloseClick}>
        {'Cancel'}
      </Button>
    </div>
  )

  const modal = (
    <Modal title='Email Notifications' onClose={onModalCloseClick} isAutoHeight={true} footer={footer}>
      <SectionRoot>
        <Section>
          <SectionHeader>Notification Type</SectionHeader>
          <SelectDropdown 
            options={selectOptions}
            value={selNotificationType}
            onChange={setSelNotificationType}
          />
        </Section>

        <Section>
          <SectionHeader>Email Preview</SectionHeader>
          <EmailTemplate emailTemplateId={selNotificationType} order={order} height='275px' />
        </Section>

        <Section>
          <SectionHeader>Actions</SectionHeader>
          <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'normal' }}>
            {`This will send a test email of the selected type to you (${email}). This is meant for testing purposes.`}
          </div>
          <div style={{ padding: '0 30%' }}>
            <ActionButton onClick={onNotificationClick} $disabled={isBusy}>Send Test Email</ActionButton>
          </div>
        </Section>
      </SectionRoot>
    </Modal>
  )

  return (<>
    {createPortal(modal, document.body)}
  </>)
}

export default ({
  order
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (<>
    {isModalOpen &&
      <OrderNotificationTestModal onClose={() => setIsModalOpen(false)} order={order} />
    }
    <ActionButton onClick={() => setIsModalOpen(true)} $colored={true}>Email</ActionButton>
  </>)
}