import React from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useIsMobileView from "../../hooks/useIsMobileView";

export default ({
  direction,
  onClick,
  isDisabled
}) => {
  const isMobileView = useIsMobileView();

  const chevronWrapperStyle = {
    cursor: isDisabled ? 'default' : 'pointer',
    position: 'absolute',
    top: isMobileView ? 'auto' : '50%',
    bottom: isMobileView ? '20px' : 'auto',
    left: direction === 'left' ? (isMobileView ? '7px' : '15px') : 'auto',
    right: direction === 'right' ? (isMobileView ? '7px' : '15px') : 'auto',
    background: isDisabled ? '#7E7E7ECC' : '#7E7E7E',
    padding: isMobileView ? '15px' : '20px',
    borderRadius: '50%'
  }

  const chevronStyle = {
    color: isDisabled ? '#CECECECC' : '#FFFFFF',
    fontSize: isMobileView ? '18px' : '34px',
    position: 'relative',
    top: '1px',
    left: direction === 'left' ? '-3px' : 'auto',
    right: direction === 'right' ? '-3px' : 'auto',
  }

  return (
    <div style={chevronWrapperStyle} onClick={onClick} >
      {
        direction === 'left' 
        ?
        <FaChevronLeft style={chevronStyle} />
        :
        <FaChevronRight style={chevronStyle} />
      }
    </div>
  )
}