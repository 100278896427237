import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { SearchDropdown } from '@prosolve/common-components-ui'
import useFetch from '../../../hooks/useFetch';
import useShipmentSnackbar from '../../Shared/useShipmentSnackbar';
import OptionalFormFieldWrapper from '../../Shared/OptionalFormFieldWrapper';

const Subtext = styled.div`
  font-size: 14px;
  font-style: italic;
  color: gray;
`

export default ({
  accountId,
  value: defaultValue,
  onChange: onChangeEvent,
}) => {
  const fetch = useFetch();

  const [isLoading, setIsLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const shipmentSnackbar = useShipmentSnackbar()

  const filteredOptions = (
    contacts?.length === 0 ? [{ text: '', value: '' }] : 
    contacts.map(a => {
      return {
        text: a.Name,
        value: a.Id,
      }
    })
  )

  const fetchContacts = async (search, isIgnoreAccount) => {
    try {
      setIsLoading(true);
      const filters = [
        ...(search ? [`&search=${search}`] : []),
        ...(accountId && !isIgnoreAccount ? [`&accountId=${accountId}`] : [])
      ]
      const response = await fetch(`accounts/contacts${filters ? `?${filters.join('&')}` : ''}`, { method: 'GET' })
      const jsonData = await response.json();
      setContacts(jsonData.error ? [] : jsonData)
      if (jsonData.error) {
        shipmentSnackbar.newMessage({ message: 'Error getting contacts', severity: 'failure' })
      }
      setIsLoading(false);
      return (jsonData.error ? [] : jsonData)
    } catch (e) {
      console.log(e)
      shipmentSnackbar.newMessage({ message: 'Error getting contacts', severity: 'failure' })
      setContacts([])
      setIsLoading(false);
    }
    return []
  }

  const updateSearch = async (value, isIgnoreAccount) => {
    await fetchContacts(value, isIgnoreAccount)
  }

  const onSearch = (value) => {
    const isIgnoreAccount = (value || '').startsWith('all:')
    const searchValue = value.replace('all:','')
    updateSearch(searchValue, isIgnoreAccount)
  }

  const onChange = (value) => {
    const contact = contacts.find(c => c.Id === value)
    setSelectedValue(contact)
    if (onChangeEvent) {
      onChangeEvent(contact)
    }
  }

  useEffect(() => {
    updateSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  return (
    <OptionalFormFieldWrapper isEnabled={true} label={'Contact Search'}>
      <SearchDropdown
        value={selectedValue}
        options={filteredOptions}
        onChange={onChange}
        onSearch={onSearch}
        isLoading={isLoading}
        maxHeight='200px'
      />
      <Subtext>To search outside the selected account, prefix your search with, 'all:'. For example: 'all:eric'</Subtext>
    </OptionalFormFieldWrapper>
  );
}