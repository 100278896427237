import React from 'react';

export default ({
  opportunity
}) => {
  const {
    CloseDate, 
    Created_Date_Formula__c
  } = opportunity

  return (
    <div>
      <div style={{ fontSize: '15px', fontWeight: 'bold', marginBottom: '20px' }}>
        {'Details'}
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        color: '#565656',
        fontSize: '14px'
      }}>
        <div style={{ display: 'flex', marginBottom: '5px' }}>
          <div style={{ width: '80px', color: '#080808', fontSize: '15px'}}>{'Created'}</div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div>{Created_Date_Formula__c}</div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '5px' }}>
          <div style={{ width: '80px', color: '#080808', fontSize: '15px'}}>{'Closed'}</div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div>{CloseDate}</div>
          </div>
        </div>
      </div>
    </div>
  )
}