import { Footer, Main, MainContent } from "@prosolve/common-components-ui";
import useIsMobileView from "../../hooks/useIsMobileView";
import OpportunityCards from "./OpportunityCards";

const SectionDivider = () => <div style={{ height: '20px' }}>&nbsp;</div>

export default () => {
  const isMobileView = useIsMobileView();

  return (
    <Main $isMobileView={isMobileView} $backgroundColor={'inherit'} style={{ padding: '10px' }}>
      <MainContent $isMobileView={isMobileView} style={{ maxWidth: 'none', width: '90%' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{ fontSize: '28px', fontWeight: 'bold' }}>
            {'Dashboard'}
          </div>
        </div>
        <OpportunityCards />
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}