import Cookies from "js-cookie"
import useProduct from "./useProduct"

/*
function isCSV(file) {
  return new Promise((resolve) => {
    Papa.parse(file, {
      preview: 1, // only read first row
      complete: function(results) {
        if (results && results.errors.length === 0 && results.data.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      error: function() {
         resolve(false);
      }
    });
  });
}
*/

const validateImport = (importArray, products) => {
  // The value true|false marks if the field is required to have data
  const columns = {
    contactName: { required: true },
    contactEmail: { required: true },
    contactPhone: { required: true },
    address: { required: true },
    city: { required: true },
    state: { required: true },
    zip: { required: true }
  }
  products.forEach(p => {
    columns[p.name] = { 
      product: true 
    }
  })

  //const columnCount = Object.entries(columns).keys().toArray().length

  const errors = importArray.reduce((acc, curr, i) => {
    Object.entries(curr).forEach(entry => {
      const [ key, value ] = entry
      
      if (columns[key] === undefined) {
        acc.push(`(row ${i}) '${key}' is not a valid column`)
        return
      }

      const isRequired = columns[key]?.required
      if (isRequired && !value) {
        acc.push(`(row ${i}) '${key}' is required`)
      }

      const isProduct = columns[key]?.product
      if (isProduct && value && isNaN(parseInt(value))) {
        acc.push(`(row ${i}) '${key}' must be a number`)
      }
    })

    return acc
  }, [])

  return errors
}

export default () => {
  const { fetchProducts } = useProduct()

  return {
    createImportTemplate: async (opportunity) => {
      const products = await fetchProducts()

      const importTemplate = {
        contactName: opportunity.Account.Shipping_Contact_Name__c,
        contactEmail: opportunity.Account.Shipping_Contact_Email__c,
        contactPhone: opportunity.Account.Shipping_Contact_Phone_Number__c,
        address: opportunity.Account.ShippingStreet || opportunity.Account.BillingStreet,
        city: opportunity.Account.ShippingCity || opportunity.Account.BillingCity,
        state: opportunity.Account.ShippingState || opportunity.Account.BillingState,
        zip: opportunity.Account.ShippingPostalCode || opportunity.Account.BillingPostalCode,
      }
      products.forEach(p => {
        importTemplate[p.name] = undefined
      })

      return importTemplate
    },

    processImport: async (opportunity, implementation, importArray) => {
      if (!importArray || importArray.length <= 0) return

      const products = await fetchProducts()

      const errors = validateImport(importArray, products)
      if (errors.length > 0) {
        return {
          errors
        }
      }

      const ordersArr = importArray.map(row => {
        return {
          accountId: opportunity.Account.Id,
          implementationId: implementation || undefined,
          opportunityId: opportunity.Id,
          contactName: row.contactName,
          contactEmail: row.contactEmail,
          contactPhone: row.contactPhone,
          address: row.address,
          city: row.city,
          state: row.state,
          zip: row.zip,
          requester: Cookies.get('name'),
          products: products.reduce((acc, curr) => {
            const quantity = row[curr.name]
            if (quantity && parseInt(quantity) > 0) {
              acc.push({
                name: curr.name,
                sku: curr.reference_id,
                quantity: quantity
              })
            }
            return acc
          }, []),
        }
      }).filter(order => {
        return (order.products?.length > 0)
      })
      return ordersArr
    },

    createExport: (orders) => {
      const products = new Set(orders.map(o => o.data).flatMap(d => d?.products).flatMap(p => p?.name))
      const shipments = new Set(orders.map(o => o.dataSb).flatMap(d => d?.shipments).flatMap(s => s?.products).flatMap(p => p?.name))
      const productsMap = products.union(shipments).values().filter(p => p !== undefined).reduce((acc, curr) => {
        acc[curr] = 0
        return acc
      }, {})
  
      const filteredOrders = orders
        .reduce((acc, curr) => {
          const order = (curr.dataSb ?? curr.data)
          const isDb = (order.source === 'db')
          const orderExport = {
            id: (isDb ? order._id : order.id),
            source: (isDb ? 'Draft Order' : 'Finalized / Shipbob Order'),
            status: order.status,
            trackingNumber: undefined,
            trackingCarrier: undefined,
            trackingUrl: undefined,
            requester: order.requester,
            contactName: order.contactName,
            contactPhone: order.contactPhone,
            contactEmail: order.contactEmail,
            address: order.address,
            city: order.city,
            state: order.state,
            zip: order.zip,
            ...productsMap
          }
  
          if (order.products) {
            order.products?.forEach(p => {
              orderExport[p.name] = p.quantity
            })
            acc.push(orderExport)
          }
          
          order.shipments?.forEach(s => {
            const oSb = {...orderExport}
            oSb.trackingNumber = s.tracking?.tracking_number
            oSb.trackingCarrier = s.tracking?.carrier
            oSb.trackingUrl = s.tracking?.tracking_url
            s.products.forEach(p => {
              oSb[p.name] = p.quantity
            })
            acc.push(oSb)
          })
  
          return acc
        }, [])
      return filteredOrders
    }
  }
}