import { FaExternalLinkAlt } from "react-icons/fa"
import styled from "styled-components"

const TrackingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  margin-top: 5px;
  color: #0280c6;
  cursor: pointer;
`

export default ({
  shipment,
}) => {
  const {
    tracking
  } = shipment

  const {
    tracking_number,
    tracking_url
  } = tracking || {}

  const onTrackingClick = () => {
    window.open(tracking_url, tracking_number)
  }

  return (<>
    {tracking_number && 
      <TrackingWrapper title={tracking_number} onClick={onTrackingClick}>
        Track Shipment <FaExternalLinkAlt />
      </TrackingWrapper>
    }
  </>)
}