import React from "react";

export default ({
  tabs = [],
  selectedTab,
  setSelectedTab,
  suppressBorderBottom,
  suppressSelectionBorderBottom,
  textStyle = {}
}) => {
  return (
    <div style={{
      display: 'flex',
      paddingLeft: '20px',
      borderBottom: suppressBorderBottom ? 'none' : '1px solid #C2C9D1'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        rowGap: '15px',
        columnGap: '15px',
        flexWrap: 'wrap'
      }}>
        {
          tabs.map(tab => {
            const isSelected = selectedTab === tab;
            return (
              <div
                key={tab}
                style={{
                  padding: '8px 12px 6px 12px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  position: 'relative',
                  top: '1px',
                  ...textStyle,
                  color: isSelected ? '#0280c6' : '#7e7e7e',
                  borderBottom: suppressSelectionBorderBottom ? 'none' : (isSelected ? '2px solid #0280c6' : '2px solid transparent'),
                }}
                onClick={() => {
                  if (selectedTab !== tab) {
                    setSelectedTab(tab)
                  }
                }}
              >
                {tab}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}