import React, { useState, useEffect, useRef } from "react";
import useForm from './hooks/useForm';
import { FormField, Button, LoadingIcon } from '@prosolve/common-components-ui'
import useFetch from './hooks/useFetch';
import background from './images/background-blue.png';
import useMediaQuery from './hooks/useMediaQuery';

export default () => {
  const isSmallView = useMediaQuery('(max-width: 620px)');

  const fetch = useFetch();

  const [isBusy, setIsBusy] = useState(false);
  const [showInvalidLogin, setShowInvalidLogin] = useState(false);

  const formSchema = {
    requiredFields: ['email', 'password'],
    initData: {
      email: '',
      password: ''
    }
  }

  const formData = useForm(formSchema)

  // Block below is necessary for password managers that auto fill inputs which never trigger components 'onChange' events
  const wrapperElRef = useRef()
  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (wrapperElRef.current) {
        const inputs = wrapperElRef.current.getElementsByTagName('input')
        const userInput = inputs[0]
        const passwordInput = inputs[1]
        formData.setValues({ ...formData.values, email: (userInput?.value || ''), password: (passwordInput?.value || '') })
      }
    });

    if (wrapperElRef.current) {
      const inputs = wrapperElRef.current.getElementsByTagName('input')
      const userInput = inputs[0]
      if (userInput) {
        observer.observe(userInput, { attributes: true, childList: true, subtree: true });
      }
      const passwordInput = inputs[1]
      if (passwordInput) {
        observer.observe(passwordInput, { attributes: true, childList: true, subtree: true });
      }
    }

    return () => observer.disconnect()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSubmit = async () => {
    if (formData.isValid()) {
      setIsBusy(true)

      const response = await fetch(
        `auth/login`, 
        { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formData.values)
        }
      )

      const jsonData = await response.json() || {};

      const { status = '' } = jsonData;

      if (status) {
        window.location.reload()
      } else {
        setShowInvalidLogin(true)
      }

      setIsBusy(false)
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickSubmit();
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: isSmallView ? 'column' : 'row',
      rowGap: '15px',
      // padding: '20px',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'auto',
      overflowX: 'hidden',
      // background: '#ffffff'
      background: `url(${background})  top left/cover no-repeat fixed`
    }}>

      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isSmallView ? 'flex-start' : 'center',
        alignItems: 'center',
        padding: '20px',
        zIndex: '100',
        marginTop: isSmallView ? '-15px' : '0px'
      }}>

       <div ref={wrapperElRef} style={{ display: 'flex', flexDirection: 'column' }}>

          <div style={{ flex: 1, display: 'flex', marginBottom: '20px', textAlign: isSmallView ? 'center' : 'center' }}>
            <div style={{ color: '#ffffff'}}>
              <div style={{ fontWeight: 'bold', fontSize: '40px', marginBottom: '40px'}}>{"Please Login"}</div>
              <div style={{ fontSize: '26px', maxWidth: isSmallView ? 'none' : '600px'}}>
                {"The page you are trying to access is restricted. Please login to your account to gain access."}
              </div>
            </div>
          </div>

          <FormField
            fieldStyle={{ 
              fontSize: '18px', 
              textAlign: isSmallView ? 'center' : 'left',
            }}
            label='Email'
            labelStyle={{ color: '#ffffff', fontWeight: 'bold'}}
            fieldId='email'
            type='text'
            // placeholder='Email'
            formData={formData}
            onKeyDown={onKeyDown}
            isLoginField={true}
          />

         <div style={{ marginTop: '10px' }}>&nbsp;</div>

          <FormField
            fieldStyle={{ 
              fontSize: '18px', 
              textAlign: isSmallView ? 'center' : 'left',
            }}
            label='Password'
            labelStyle={{ color: '#ffffff', fontWeight: 'bold'}}
            fieldId='password'
            type='password'
            // placeholder='Password'
            formData={formData}
            onKeyDown={onKeyDown}
            isLoginField={true}
            hasPasswordToggle={true}
          />

          <div style={{ height: '25px', position: 'relative'}}>

          {
            (!isBusy && showInvalidLogin) ?
            <div style={{
              color: '#ef0c0c',
              fontStyle: 'italic',
              fontSize: '14px',
              position: 'absolute',
              top: '3px',
              left: '3px'
            }}>
              {'Unable to login'}
            </div>
            :
            <div>&nbsp;</div>
          }

          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallView ? 'center' : 'center' }}>
            <Button 
              style={{ 
                display: 'inline-flex', 
                marginRight: '10px', 
                fontWeight: 'bold', 
                fontSize: '24px',
                width: '150px', 
                height: '40px',
                background: 'transparent'
              }} 
              onClick={onClickSubmit} 
              $colored={true}
            >
                {'SUBMIT'}
            </Button>
            {
              isBusy &&
              <LoadingIcon />
            }
          </div>

        </div>
      </div>
    </div>
  )
}