import moment from "moment"
import Cookies from 'js-cookie'

export default () => {
  return {
    reportTypes: [
      { id: 'WaitingThisWeek', title: 'Closed This Week Waiting Shipments', buttonTitle: 'This Week' },
      { id: 'WaitingOlder', title: 'Older Still Waiting Shipments', buttonTitle: 'Older' },
      { id: 'InProgress', title: 'In Progress', buttonTitle: 'In Progress' },
      { id: 'CompletedLast3Weeks', title: 'Completed Last 3 Weeks', buttonTitle: '3 Weeks' },
      { id: '70Percent', title: '70% Opportunities', buttonTitle: '70%' },
      { id: 'ByTerritory', title: 'My Accounts', buttonTitle: 'My Accounts' },
      { id: 'Recent', title: 'Recent Opportunities', buttonTitle: 'Recent' },
      { id: 'Flagged', title: 'Flagged', buttonTitle: 'Flagged' },
    ],

    // IsShipRequestedComplete, IsShipTrackingComplete, IsShipDeliveryComplete
    filterOpportunitiesByReportType(opportunities, reportType) {
      const territory = Cookies.get('territory')
      return opportunities.filter(o => {
        if (reportType === 'WaitingThisWeek' && !(moment(o.CloseDate) > moment().subtract('1', 'week') && moment(o.CloseDate) <= moment() && !o.IsShipRequestedComplete)) return false
        if (reportType === 'WaitingOlder' && !(moment(o.CloseDate) < moment().add('1', 'week') && !o.IsShipRequestedComplete)) return false
        if (reportType === 'InProgress' && !(moment(o.CloseDate) > moment()) && o.IsShipRequestedComplete && !o.IsShipDeliveryComplete) return false
        if (reportType === 'CompletedLast3Weeks' && !(moment(o.CloseDate) > moment().subtract('3', 'week') && moment(o.CloseDate) <= moment() && o.IsShipDeliveryComplete)) return false
        if (reportType === '70Percent' && !(o.Probability > 70 && o.Probability < 100)) return false
        if (reportType === 'ByTerritory' && !(o.Account.Territory__c === territory)) return false
        if (reportType === 'Recent' && !(!o.CloseDate || moment(o.CloseDate) <= moment())) return false
        if (reportType === 'Flagged' && !(o.desiredArrivalDate && moment() > moment(o.desiredArrivalDate) && !o.actual_fulfillment_date)) return false
        return true
      })
    }
  }
}