import styled from "styled-components"
import ViewInventoryItemExternalButton from "./ViewInventoryItemExternalButton"

const Card = styled.div`
  background: white;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-top: 20px;
  position: relative;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 15%);
  margin: 2px 4px 4px 2px;
`

const Header = styled.div`
  background: #f8f9fc;
  border-bottom: 1px solid #dedede;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;

  .inactive & {
    color: gray;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`

const InventoryTable = styled.table`
  & {
    width: 100%
  }

  * th {
    text-align: left
  }
`

export default ({
  inventoryItem
}) => {
  const {
    is_active,
    total_awaiting_quantity,
    total_backordered_quantity,
    total_committed_quantity,
    total_exception_quantity,
    total_fulfillable_quantity,
    total_internal_transfer_quantity,
    total_onhand_quantity,
    total_sellable_quantity,
  } = inventoryItem

  return (
    
    <Card className={!is_active && 'inactive'}>
      <Header>
        <HeaderRow>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>{inventoryItem.name}</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <ViewInventoryItemExternalButton inventoryItem={inventoryItem} />
          </div>
        </HeaderRow>
      </Header>
      
      <Body>
        <div style={{ flex: 1 }}>
          <InventoryTable>
            <thead>
              <tr>
                <th>Sellable</th>
                <th>On&nbsp;Hand</th>
                <th>Committed</th>
                <th>Fulfilled</th>
                <th>Exception</th>
                <th>Backordered</th>
                <th>Incoming</th>
                <th>Transfer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{total_sellable_quantity}</td>
                <td>{total_onhand_quantity}</td>
                <td>{total_committed_quantity}</td>
                <td>{total_fulfillable_quantity}</td>
                <td>{total_exception_quantity}</td>
                <td>{total_backordered_quantity}</td>
                <td>{total_awaiting_quantity}</td>
                <td>{total_internal_transfer_quantity}</td>
              </tr>
            </tbody>
          </InventoryTable>
        </div>
      </Body>
    </Card>
  )
}