import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./LoginPage";
import RouteErrorPage from './RouteErrorPage';
import Cookies from 'js-cookie';
import {
  allowedStandardDomains,
  getClientSubdomain,
  checkHasPermissionToShippingApp,
  checkHasPermissionToAssessmentManagerApp,
  checkHasPermissionToQuestCodesApp
} from "./utils";

const PrivateRoute = ({ element: Component, ...routeProps }) => {
  // TODO: This should be eventually made more secure by being a token that will be sent to the backend
  // which can confirm its a cookie generated by us

  // Alternatively we may be able to use this same cookie, but make sure it is set to httpOnly / secure
  // This would mean however it cant be accessed by the javascript here below so we would need to make a dedicated API
  // endpoint to check if this cookie exists here and return true or false

  // Check for app level permissions and redirect to the login page if user does not have proper permissions
  const { path } = routeProps
  if ((path === 'shipping') && !checkHasPermissionToShippingApp()) return <LoginPage />
  if ((path === 'assessmentManager') && !checkHasPermissionToAssessmentManagerApp()) return <LoginPage />
  if ((path === 'questCodes') && !checkHasPermissionToQuestCodesApp()) return <LoginPage />

  const clientSubdomain = getClientSubdomain()
  const isAllowedDomain = allowedStandardDomains.indexOf(clientSubdomain) > -1;

  const isAuthorizedCookie = Cookies.get('isAuthorized');
  return (isAllowedDomain && isAuthorizedCookie && isAuthorizedCookie === 'true') ? <Component {...routeProps} /> : <LoginPage />;
};

const PublicRoute = ({ element: Component, ...routeProps }) => {
  return <Component {...routeProps} />;
};

export default ({
  routes = []
}) => {
  const parseRoute = (route) => {
    return route.map((routeObj) => {
      const {
        path,
        isPrivate,
      } = routeObj;
      const parsedRouteObj = { ...routeObj }
      if (path === '/') {
        parsedRouteObj.errorElement = <RouteErrorPage />;
      }

      if (isPrivate) {
        parsedRouteObj.element = <PrivateRoute {...routeObj} />;
      } else {
        parsedRouteObj.element = <PublicRoute {...routeObj} />;
      }
      
      return parsedRouteObj
    })
  }

  const router = createBrowserRouter(parseRoute(routes));

  return <RouterProvider router={router} />
}