export default () => {
  return {
    draftTypes: [
      { id: 'All', buttonTitle: 'Draft Orders' },
      { id: 'draft', buttonTitle: 'Draft' },
      { id: 'finalized', buttonTitle: 'Finalized' },
      { id: 'cancelled', buttonTitle: 'Cancelled' }
    ],

    orderTypes: [
      { id: 'All', buttonTitle: 'Finalized Orders' },
      { id: 'ImportReview', buttonTitle: 'Import Review' },
      { id: 'Processing', buttonTitle: 'Processing' },
      { id: 'PartiallyFulfilled', buttonTitle: 'Partially Fulfilled' },
      { id: 'Fulfilled', buttonTitle: 'Fulfilled' },
      { id: 'Cancelled', buttonTitle: 'Cancelled' },
      { id: 'Exception', buttonTitle: 'Exception' },
    ]
  }
}