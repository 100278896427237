import OpportunityCard from "./OpportunityCard"

export default ({
  opportunities,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '10px' }}>
      {opportunities.map(o => {
        return <OpportunityCard key={o.Id} opportunity={o} />
      })}
    </div>
  )
}