import { useRef, useState } from "react"
import { Button, Modal } from "@prosolve/common-components-ui"
import useFetch from "../../hooks/useFetch";
import ActionButton from "../Shared/ActionButton";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import { checkHasShipmentExperimentalEnabled } from "../../utils";
import CreateWROForm from "./CreateWROForm";

// If product is present, the form will edit instead.
export default ({
  wro,
  updateWROData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const shipmentSnackbar = useShipmentSnackbar()

  const tomorrow = (new Date())
  tomorrow.setDate(tomorrow.getDate() + 1)

  const wroFormData = useRef()
  const wroFormValidator = useRef()

  const [isBusy, setIsBusy] = useState(false);

  const fetch = useFetch();

  const isShipExpOn = checkHasShipmentExperimentalEnabled();

  const isCreate = !wro

  const createWRO = async () => {
    setIsBusy(true)
    try {

      // TODO Product dropdown returns formDataProduct.values.productId but we need inventoryId for a WRO
      const urlEndpoint = 'shipping/wro'
      const updateData = { ...wroFormData.current, ...{

        // TODO need a fulfillment center dropdown
        fulfillment_center: {
          id: 41 // Hillsboro (OH)
        }
      }}

      console.log('createWRO() wroFormData', wroFormData.current, 'updateData', updateData)
      const response = await fetch(urlEndpoint, { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData)
        }
      )

      const jsonData = await response.json();
      if (jsonData.error) {
        console.error(jsonData.error)
        shipmentSnackbar.newMessage({ message: 'Error creating WRO', severity: 'failure' })
      } else {
        updateWROData(jsonData, true)
        onClose()
      }
      
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error creating WRO', severity: 'failure' })
    }
    setIsBusy(false)
  }

  const onOpen = () => {
    setIsModalOpen(true)
    document.documentElement.style.overflow = 'hidden'
  }

  const onClose = () => {
    setIsModalOpen(false)
    document.documentElement.style.overflow = 'auto'
  }

  const onClickSubmit = () => {
    console.log('onClickSubmit() wroFormValidator', wroFormValidator, 'current()', wroFormValidator.current(), 'wroFormData', wroFormData.current)
    if (wroFormValidator.current()) {
      createWRO()
    }
  }

  const onWROFormChange = (wro) => {
    wroFormData.current = wro
  }

  const onWROFormSendValidator = (validate) => {
    wroFormValidator.current = validate
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      {/* SB_DISABLED - This is disabled until the UI is fully implemented */}
      <Button onClick={onClickSubmit} $colored={true} style={{ ...(!isShipExpOn && { display: 'none' }) }}>
        {'Submit'}
      </Button>
    </div>
  )

  return (
    <>
      {isModalOpen && 
        <Modal title={'Create Order'} onClose={onClose} footer={footer}>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <CreateWROForm initialWRO={wro} sendValidator={onWROFormSendValidator} onChange={onWROFormChange} />
          </div>
        </Modal>
      }
      {/* SB_DISABLED - This is disabled until the UI is fully implemented */}
      <ActionButton onClick={onOpen} style={{ ...(isCreate && !isShipExpOn && { display: 'none' }) }}>
        { isCreate ? 'New WRO' : 'View' }
      </ActionButton>
    </>
  )
}