/**
 * Returns a combined order record (OrderEx) with both the draft order (.data) and shipping order (.dataSb) for each result.
 */
const fetchOrders = async (options) => {
  const { fetch } = options
  delete options.fetch

  options.useCache = (options.useCache !== undefined ? options.useCache : true)
  const params = new URLSearchParams(Object.entries(options).filter(e => e[1] !== undefined))
  
  const response = await fetch(`shipping/order${params.size > 0 ? `?${params.toString()}` : ''}`, { method: 'GET' })
  const data = await response.json();
  return data
}

const fetchCreateOrder = async (options) => {
  try {
    const { fetch, formValues, isEdit, is_qfc } = options
    delete options.fetch

    const urlEndpoint = `shipping/order/${isEdit ? 'edit' : 'create'}`
    const updateData = { 
      ...formValues, 
      is_qfc 
    };
    const response = await fetch(urlEndpoint, { 
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updateData)
    })
    const jsonData = await response.json();
    if (jsonData.error) {
      return Promise.reject(jsonData.error)
    } else {
      return Promise.resolve(jsonData)
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

const fetchFinalizeOrder = async (options) => {
  try {
    const { fetch, order } = options
    delete options.fetch

    const headers = {
      "Content-Type": "application/json",
    }
    const data = JSON.stringify(order)
    const response = await fetch(`shipping/order/finalize`, { method: 'POST', headers, body: data })
    const jsonData = await response.json();
    if (jsonData.error) {
      return Promise.reject(jsonData.error)
    }
    return Promise.resolve(jsonData)
  } catch (e) {
    return Promise.reject(e)
  }
}

export default () => {
  return {
    fetchOrders,
    fetchCreateOrder,
    fetchFinalizeOrder
  }
}