import { useEffect, useState } from "react"
import useFetch from "../../hooks/useFetch"
import useFetchOpportunities from "../Shared/useFetchOpportunities"
import useOpportunityReportType from "../Shared/useOpportunityReportType"
import useShipmentSnackbar from "../Shared/useShipmentSnackbar"
import OpportunityCard from "./OpportunityCard"

export default () => {
  const [opportunities, setOpportunities] = useState()

  const fetch = useFetch()
  const shipmentSnackbar = useShipmentSnackbar()
  const { reportTypes, filterOpportunitiesByReportType } = useOpportunityReportType()
  const { fetchOpportunities: fetchOpportunitiesHook } = useFetchOpportunities()

  const fetchOpportunities = async () => {
    try {
      const opportunities = await fetchOpportunitiesHook({ fetch })
      setOpportunities(opportunities)
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting opportunities', severity: 'failure' })
    }
  }

  useEffect(() => {
    fetchOpportunities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '0px' }}>
      {reportTypes.map(r => {
        const filteredOpps = (!opportunities ? undefined : filterOpportunitiesByReportType(opportunities, r.id).slice(0,3))
        return (
          <OpportunityCard key={r.id} title={r.title} opportunities={filteredOpps} reportType={r.id} isLoading={!opportunities} />
        )
      })}
    </div>
  )
}