import React, { Children, useState } from "react"
import { FaEllipsisV } from "react-icons/fa"
import styled from "styled-components"
import { LoadingIcon, MenuPopup } from "@prosolve/common-components-ui"
import ShippingModal from "./ShippingModal"

const Ellipsis = styled.div`
  cursor: pointer;
`

const EllipsisMenu = styled.div`
  position: relative;
`

/**
 * @param {*} name - The name to be displayed in the menu
 * @param {*} onClick - The function executed when the menu item is selected/clicked. Can be asynchronous.
 */
export const EllipsisMenuItem = () => { }

/**
 * Creates an Ellipsis (3 vertical dot) menu that can be opened for more options.
 * 
 * @example
 * <EllipsisMenu>
 *   <EcllipsisMenuItem name="First" onClick={onFirstClick} />
 *   <EcllipsisMenuItem name="Second" onClick={onSecondClick} />
 * </EllipsisMenu>
 */
export default function EllipsisMenu2 ({
  children
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const optionsMap = Children.toArray(children)
  // Ignore fragments, use children instead
  .reduce((arr,curr) => {
    if (curr.type === React.Fragment) {
      arr.push(...Children.toArray(curr.props.children))
    } else arr.push(curr)
    return arr
  }, [])
  // Get map of name => component
  .reduce((arr,curr) => {
    const name = curr?.props?.name
    if (name) arr[name] = curr
    return arr
  }, {})
  const filteredOptions = [...Object.keys(optionsMap)]

  const onSelection = async (selection) => {
    const selectedOption = optionsMap[selection]
    //console.log('selection', selection, 'selectedOption', selectedOption)
    setIsBusy(true)
    await selectedOption?.props?.onClick()
    setIsBusy(false)
  }

  const onClick = (e) => {
    e.stopPropagation(); 
    setIsOpen(!isOpen)
  }
  
  return (
    <div>
      {isBusy &&
        <ShippingModal title='Please Wait...' isOpen={true} onClose={() => setIsBusy(false)} footerContent={<></>}>
          <div style={{ textAlign: 'center' }}><LoadingIcon /></div>
        </ShippingModal>
      }
      <Ellipsis onClick={onClick}>
        <FaEllipsisV />
      </Ellipsis>
      <EllipsisMenu>
        <MenuPopup 
          open={isOpen} 
          top='-2px'
          left='-105px'
          options={filteredOptions}
          onClose={() => setIsOpen(false)} 
          onSelection={onSelection} 
        />
      </EllipsisMenu>
    </div>
  )
}
