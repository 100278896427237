import LineItemDetails from "./LineItemDetails"

export default ({
  lineItems
}) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
      <div style={{
        fontSize: '15px',
        fontWeight: 'bold',
        display: 'flex'
      }}>
        {`Line Items`}
      </div>

      {lineItems?.map(i => 
        <LineItemDetails key={i.Id} lineItem={i} />
      )}
    </div>
  )
}