import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import ProductListItem from "./ProductListItem"
import { FaPlusCircle } from "react-icons/fa"

export default forwardRef(({
  products,
  onChange: onChangeParam
}, ref) => {
  const [productsCurrentData, setProductsCurrentData] = useState(products || [])
  const productListItemRefs = useRef([])

  const onChange = (index, product) => {
    const productsClone = [ ...productsCurrentData ]
    //const index = productsClone.findIndex(p => p.id === id)
    if (product) {
      productsClone[index] = product
    } else {
      productsClone.splice(index, 1)
    }
    setProductsCurrentData(productsClone)
    //console.log('productlist .onchange', index, product, productsCurrentData, productsClone)

    if (onChangeParam) onChangeParam(productsClone)
  }

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return productListItemRefs.current.reduce((prev, curr) => {
        // Note, don't use && because that will short-circuit the evaluation if the first is true and never run the second
        return prev & (curr ? curr.isValid() : true)
      }, true)
    }
  }), [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', flex: 1 }}>
      {products?.map((p, index) => 
        <ProductListItem 
          ref={r => productListItemRefs.current[index] = r} 
          key={p.sku || ('i' + index)} product={p} 
          onChange={p => onChange(index, p)} 
          onRemove={p => onChange(index, undefined)} 
        />
      )}
      <span style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => onChange(productsCurrentData.length, {})}><FaPlusCircle /></span>
    </div>
  )
})