import { useEffect, useState } from 'react'
import { FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'
import useShipmentSnackbar from './useShipmentSnackbar'

export default ({
  formData,
  selectedVal,
  setSelectedVal,
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [fulfillmentCenters, setFulfillmentCenters] = useState([])
  const fetch = useFetch()

  const shipmentSnackbar = useShipmentSnackbar()

  const filteredOptions = fulfillmentCenters?.filter(f => 
    ((searchVal || '').length === 0 ? true : f.name.toLowerCase().indexOf((searchVal+'').toLowerCase()) > -1)
  )
  .map(f => {
    return {
      text: f.name,
      value: f.id,
    }
  }) || []
  filteredOptions.unshift({
    text: '',
    value: ''
  })

  const fetchFulfillmentCenter = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`shipping/fulfillmentCenters`, { method: 'GET' })
      const jsonData = await response.json();
      setFulfillmentCenters(jsonData.error ? [] : jsonData)
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting fulfillment centers', severity: 'failure' })
      setFulfillmentCenters([])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchFulfillmentCenter()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormField
      style={{ flex: 1 }}
      fieldId='id'
      type='search'
      label='Fulfillment Center'
      formData={formData}
      options={filteredOptions}
      onChange={(val) => setSelectedVal(val)}
      onSearch={(val) => setSearchVal(val)}
      isLoading={isLoading}
      maxHeight='200px'
      width='350px'
    />
  )
}