import React from 'react';
import styled from 'styled-components';
import ImagePlaceholder from '../../../images/image-placeholder.jpg'

const Card = styled.div`
  display: flex;
  color: #565656;
  font-size: 14px;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 7px;
  margin-top: 2px;
  min-width: 200px;
`

export default ({
  product
}) => {
  const {
    name,
    image,
    sku,
    quantity,
    purchasedQuantity
  } = product; 

  const imageSrc = image || ImagePlaceholder;

  return (
    <Card>
      <img src={imageSrc} style={{ height: '70px', marginRight: '10px' }} alt=""/>
      <Details>
        <div style={{ color: '#080808', fontSize: '16px'}}>{name || sku}</div>
        <div>{`Quantity: ${quantity || ''}`}</div>
        {purchasedQuantity !== undefined &&
          <div>{`Purchased Quantity: ${purchasedQuantity}`}</div>}
      </Details>
    </Card>
  )
}