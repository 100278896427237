import useFetch from "../../hooks/useFetch"

export default () => {
  const fetch = useFetch()

  return {
    /** { email, orderId, notificationType } */
    sendOrderNotification: async (params) => {
      const response = await fetch(`shipping/order/notification`, { 
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params)
      })
      const responseJson = await response.json()
      if (!responseJson.status) throw new Error(responseJson.message)
      return responseJson
    },
    
    NotificationTypes: {
      'OrderCreated': { id: 'OrderCreated', name: 'Order Created', emailTemplateId: 'OrderCreated' },
      'OrderDelivered': { id: 'OrderDelivered', name: 'Order Delivered', emailTemplateId: 'OrderDelivered' }
    }
  }
}