import { forwardRef, useImperativeHandle } from "react"
import { FaSync } from "react-icons/fa"
import useFetch from "../../hooks/useFetch";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";

export default forwardRef(({
  text = 'Refresh',
  onProductsRefresh: {
    start: onProductsRefreshStart = () => true,
    done: onProductsRefreshDone = () => {},
  } 
}, ref) => {
  const fetch = useFetch()

  const shipmentSnackbar = useShipmentSnackbar()

  useImperativeHandle(ref, () => ({
    refreshCache: () => {
      fetchProductRefresh();
    },
  }));

  const fetchProductRefresh = async () => {
    try {
      if (onProductsRefreshStart() !== false) {

        const options = { 
          useCache: false,
          limit: 0
        }
        const query = Object.entries(options).reduce((qs, e) => (qs ? qs + '&' : '?') + e.join('='), '')
        const response = await fetch(`shipping/product${query}`, { method: 'GET' })
        const result = await response.json();

        onProductsRefreshDone(result)
      }
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error refreshing product(s)', severity: 'failure' })
    }
  }

  return (
    <FaSync title='Refresh data' onClick={fetchProductRefresh} style={{ cursor: 'pointer' }}>{text}</FaSync>
  )
})