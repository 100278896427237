import React from 'react';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import CancelShipmentButton from './CancelShipmentButton';
import ViewShipmentExternalButton from './ViewShipmentExternalButton';
import Products from './Products';
import TrackingButton from './TrackingButton';

export default ({
  shipment,
  updateSbOrderData
}) => {
  const {
    id,
    products,
    status,
    status_details
  } = shipment

  const isException = (status === 'Exception')
  const isActive = (status !== 'Cancelled')

  return (
    <div>
      <div style={{
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: '20px',
        display: 'flex'
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span>{`Shipment #${id}`}</span>
          <span style={{ color: '#565656', fontSize: '12px', fontStyle: 'italic', fontWeight: 'normal' }}>
            {status_details?.length > 0 &&
              <span style={{ cursor: 'help', marginRight: '3px', fontSize: '11px', position: 'relative', top: '1px' }}>
                {isException 
                  ?
                  <FaExclamationTriangle style={{ color: (isException ? '#ef0c0c' : '#FEB800') }}
                    title={status_details.map(sd => sd.name + ': ' + sd.description).join('\n')} /> 
                  :
                  <FaInfoCircle
                    title={status_details.map(sd => sd.name + ': ' + sd.description).join('\n')} />
                }
              </span>
            }
            <span>{`${status}`}</span>
          </span>
          <TrackingButton shipment={shipment} />
        </div>
        {isActive &&
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px', fontSize: '12px' }}>
            <ViewShipmentExternalButton shipment={shipment} />
            <CancelShipmentButton shipment={shipment} updateSbOrderData={updateSbOrderData} />
          </div>
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
        <Products products={products} />
      </div>
    </div>
  )
}