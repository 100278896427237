import React, { useState } from "react";
import { Modal, Button } from '@prosolve/common-components-ui'
import useFetch from '../../../hooks/useFetch';
import { checkHasPermissionShipmentTrackingPublish } from "../../../utils";
import ActionButton from "../../Shared/ActionButton";
import useShipmentSnackbar from "../../Shared/useShipmentSnackbar";

export default ({
  shipment,
  updateSbOrderData
}) => {
  const {
    id,
    order_id,
  } = shipment;

  const [isShowModal, setIsShowModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const fetch = useFetch();

  const shipmentSnackbar = useShipmentSnackbar()
  
  const hasPublish = checkHasPermissionShipmentTrackingPublish()

  const cancelShipment = async () => {
    setIsBusy(true)
    try {
      const updateData = JSON.stringify({
        shipmentId: id,
        orderId: order_id,
      })
      const headers = {
        "Content-Type": "application/json",
      }
      const response = await fetch('shipping/shipment/cancel', { method: 'POST', headers, body: updateData })

      const jsonData = await response.json();
      if (jsonData.error) {
        console.error(jsonData.error)
        shipmentSnackbar.newMessage({ message: 'Error cancelling shipment', severity: 'failure' })
      } else {
        updateSbOrderData(jsonData.data, jsonData.dataSb)
        onClose()
      }
      
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error cancelling shipment', severity: 'failure' })
    }
    setIsBusy(false)
  }

  const onClose = () => {
    setIsShowModal(false)
  }

  const onClickSubmit = () => {
    cancelShipment()
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose} style={{ disabled: isBusy }}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true} style={{ disabled: isBusy }}>
        {'Submit'}
      </Button>
    </div>
  )

  return (<>
    { hasPublish && <>
      { isShowModal &&
        <Modal title='Cancel Shipment' onClose={onClose} footer={footer} isAutoHeight={true}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '16px',
            fontWeight: 'normal'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '15px'
            }}>
              {`Are you sure you want to cancel shipment #${shipment.id}?`}
            </div>
          </div>
        </Modal>
      }

      <ActionButton onClick={() => setIsShowModal(true)}>
        Cancel&nbsp;Shipment
      </ActionButton>
    </>}
  </>)
}