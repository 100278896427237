import React from 'react';
import styled from 'styled-components';

const Card = styled.div``

const Header = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  color: #565656;
  font-size: 14px;
`

const Section = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const SectionHeader = styled.div`
  width: 80px;
  color: #080808;
  font-size: 15px;
  min-width: 80px;
`

const SectionCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 150px;
`

export default ({
  account
}) => {
  const {
    Name,
    Phone,
    ShippingCity : city,
    ShippingPostal: zip,
    ShippingState: state,
    ShippingStreet: address1,
    
    email,
    address2
  } = account;

  return (
    <Card>
      <Header>
        {'Recipient Details'}
      </Header>
      <Body>
        <Section>
          <SectionHeader>{'Name'}</SectionHeader>
          <SectionCol>
            <div>{Name}</div>
            { Boolean(email) && <div style={{ color: '#0280c6' }}>{email}</div> }
            { Boolean(Phone) && <div>{Phone}</div> }
          </SectionCol>
        </Section>
        <Section>
          <SectionHeader>{'Address'}</SectionHeader>
          <SectionCol>
            <div>{address1 || ''}</div>
            { Boolean(address2) && <div>{address2}</div> }
            <div>{`${city ? `${city},` : ''} ${state || ''} ${zip || ''}`}</div>
          </SectionCol>
        </Section>
      </Body>
    </Card>
  )
}