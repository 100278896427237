import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import { Button, Modal } from "@prosolve/common-components-ui"
import useFetch from "../../hooks/useFetch"

export default () => {
  const [queryResult, setQueryResult] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const adminPermissions = Cookies.get("adminPermissions")
  const isAnyAdmin = (!!adminPermissions)
  if (!isAnyAdmin) return <></>

  const saveData = useQuerySaveData()
  const [query, setQuery] = useState(saveData.currentQuery)

  const fetch = useFetch()

  const displayQuerySf = (jsonOrArray) => {
    //return JSON.stringify(jsonOrArray, 2)
    if (jsonOrArray?.length === 0) return <table><tbody><tr><td>No Results</td></tr></tbody></table>

    const first = jsonOrArray?.[0]
    const keys = Object.keys(first)

    const toExpandableStringElement = (textorObj) => {
      //console.log('toExpandableStringElement ', typeof(textorObj), textorObj)
      const str = (typeof(textorObj) === 'object' ? JSON.stringify(textorObj) : ''+textorObj)
      if (str.length < 30) return str

      return (
        <span title={str}>
          ${str.substring(0, 28)}...
        </span>
      )
    }

    return (
      <table>
        <thead>
          <tr>
            {keys.map((k, ki) => <th key={ki}>{k}</th>)}
          </tr>
        </thead>
        <tbody>
          {jsonOrArray.map((item, ii) => 
            <tr key={ii}>
              {keys.map((k,ki) => <td key={ki}>{toExpandableStringElement(item[k])}</td>)}
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  const fetchQuerySf = async () => {
    try {
      setIsLoading(true)
      const data = { query: query };
      const response = await fetch('shipping/query', { 
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }
      )

      const jsonData = await response.json();
      //console.log('fetchQuerySf', jsonData)
      setQueryResult(jsonData.error ? JSON.stringify(jsonData.error, 2) : displayQuerySf(jsonData))
    } catch (e) {
      console.error(e)
      setQueryResult(<div>Error: {e.message}</div>)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = () => {
    saveData.appendQuery(query)
    fetchQuerySf(query)
  }

  const onKeyDownQuery = (event) => {
    if (event.ctrlKey && event.key === 'ArrowUp') {
      setQuery(saveData.changeCurrentQuery(-1))
    } else if (event.ctrlKey && event.key === 'ArrowDown') {
      setQuery(saveData.changeCurrentQuery(1))
    } else if (event.ctrlKey && event.key === 'Enter') {
      onSubmit()
    }
  }

  // Field and table names if we want to provide autocomplete (or just a list on the side)
  /*
SELECT KeyPrefix, QualifiedApiName, Label, IsQueryable, IsDeprecatedAndHidden, IsCustomSetting
FROM EntityDefinition
WHERE IsCustomizable = true AND IsCustomSetting = false
  */

  return (<>
    <QueryModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
            <div style={{ fontSize: '0.8em', color: 'gray', marginBottom: '5px' }}>
              While focused on the textarea: [CTRL + Enter = Submit] [CTRL + Up/Down = Cycle through query history]
            </div>
            <textarea 
              autoFocus
              onKeyDown={onKeyDownQuery}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              style={{ height: '6em', marginBottom: '10px' }}
            ></textarea>
            <button onClick={onSubmit}>Submit</button>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ fontWeight: 'bold' }}>Tips / Examples</div>
            <dl>
              <dt style={{ fontWeight: 'bold' }}>Standard vs Custom fields/tables</dt>
              <dd>
                select Id, Name, Notes__c from Accounts
              </dd>
              <dt style={{ fontWeight: 'bold' }}>Select *</dt>
              <dd>
                select FIELDS(ALL) from Accounts limit 100
              </dd>
              <dt style={{ fontWeight: 'bold' }}>Always true clause</dt>
              <dd>
                select Id, Name from Accounts where Id != null
              </dd>
              <dt style={{ fontWeight: 'bold' }}>Joining tables</dt>
              <dd>
                select Id, Name, Contact__r.Name from Accounts
              </dd>
            </dl>
          </div>
        </div>
        <div style={{ height: '400px' }}>
          {
            isLoading ? 
              <div></div> : 
              queryResult
          }
        </div>
      </div>
    </QueryModal>
  </>)
}

/**
 * SAVE DATA
 */
const SD_COOKIES_KEY = 'querySf'
const SD_DEFAULT = { 
  version: 1, 
  historyIndex: 0, 
  history: [''] 
}
const useQuerySaveData = () => {

  // Load saved data
  var cookieSavedData
  try {
    cookieSavedData = JSON.parse(Cookies.get(SD_COOKIES_KEY) || {})
    //console.log('Save data loaded', cookieSavedData)
  } catch (e) {
    console.log('No save data, or save data is corrupt. Generating default data.')
    cookieSavedData = { ...SD_DEFAULT }
  }
  const [savedData, setSavedData] = useState(cookieSavedData)
  
  const { version, history, historyIndex } = savedData

  // Watch for updates and save changes
  useEffect(() => {
    //console.log('saveData changed', savedData)
    if (version) {
      //console.log('saveData saving')
      Cookies.set(SD_COOKIES_KEY, JSON.stringify(savedData))
    }
  }, [savedData, version])

  // Exports
  const exports = {
    get: () => savedData,
    currentQuery: () => history[historyIndex || 0],
    changeCurrentQuery: (amount) => {
      const clone = { ...savedData }
      clone.historyIndex = Math.min(clone.history.length - 1, Math.max(0, clone.historyIndex + amount))
      setSavedData(clone)
      return history[clone.historyIndex || 0]
    },
    appendQuery: (query) => {
      const clone = { ...savedData }
      if (clone.history?.[clone.history.length-1] !== query) {
        clone.history.push(query)
        clone.history.splice(30, 10)
      }
      clone.historyIndex = clone.history.length - 1
      setSavedData(clone)
    }
  }

  return exports
}

/**
 * MODAL
 */
const QueryModal = ({ 
  isOpen: isOpenParam,
  onClose: onCloseParam, 
  children
}) => {
  const [isOpen, setIsOpen] = useState(isOpenParam)

  const onClose = () => {
    setIsOpen(false)
    onCloseParam()
  }

  // Hold control and press, "Q" 3 times in a row to open
  useEffect(() => {
    const arr = []
    document.addEventListener('keydown', (event) => {
      //console.log('Key down:', event.key);

      // altKey, ctrlKey
      if (event.ctrlKey && event.key === 'q') {
        arr.push(event.key)

        if (arr.length === 3) {
          setIsOpen(true)
        }
      } else {
        arr.splice(0, arr.length)
      }
    });
  }, [])

  useEffect(() => {
    setIsOpen(isOpenParam)
  }, [isOpenParam])

  useEffect(() => {
    document.body.style.overflow = (isOpen ? 'hidden' : 'auto') 
  }, [isOpen])

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      <Button onClick={onClose} $colored={true}>
        {'Close'}
      </Button>
    </div>
  )

  return (
    <>
      {isOpen && 
        <Modal title={'Query SF'} footer={footer} isFullScreen={true} onClose={onClose}>
          {children}
        </Modal>
      }
    </>
  )
}