import React from 'react';
import useIsMobileView from "../hooks/useIsMobileView";
import { Main, MainContent, Section, TopNavBar, Footer } from '@prosolve/common-components-ui'
import useFetch from '../hooks/useFetch';

// import BulkUploadQuestCodes from './testComponents/BulkUploadQuestCodes';
// import AddSingleQuestCode from './testComponents/AddSingleQuestCode';
// import ViewAndDeleteQuestCodes from './testComponents/ViewAndDeleteQuestCodes';

import QuestCodesTable from './QuestCodesTable/QuestCodesTable';

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default ({ isPrivate }) => {
  const fetch = useFetch();

  const isMobileView = useIsMobileView();
  
  return (
    <>
    { isPrivate && <TopNavBar fetch={fetch} />}
    <Main 
      $isMobileView={isMobileView} 
      $backgroundColor={'#ffffff'}
      $hasNavHeader={isPrivate}
      style={{ padding: '5px' }}
    >
      <MainContent $isMobileView={isMobileView} style={{ maxWidth: '1250px' }}>
        <div style={{ padding: '0px' }}>
          <Section style={{ padding: '0px' }}>
            {/* <AddSingleQuestCode />
            <BulkUploadQuestCodes />
            <ViewAndDeleteQuestCodes /> */}
            <QuestCodesTable />
          </Section>
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
    </>
  )
}
