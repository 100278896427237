import { useEffect, useState } from 'react'
import { FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'
import useShipmentSnackbar from './useShipmentSnackbar'

export default ({
  formData,
  selectedVal,
  setSelectedVal,
  isUseSku,
  // Optional, product data will be queried if not provided.
  productsData,
}) => {
  const [searchVal, setSearchVal] = useState((!isUseSku ? formData.values.id : formData.values.reference_id))
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState(productsData || [])
  const fetch = useFetch()

  const shipmentSnackbar = useShipmentSnackbar()

  const filteredOptions = products
  .filter(p => 
    p.name?.toLowerCase().indexOf(searchVal?.toLowerCase()) > -1 ||
    (!isUseSku ? p.id : p.reference_id)?.toLowerCase().indexOf(searchVal?.toLowerCase()) > -1
  ).map(a => {
    return {
      text: a.name,
      value: (!isUseSku ? a.id : a.reference_id),
    }
  })

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`shipping/product`)
      const jsonData = await response.json();
      setProducts(jsonData.error ? [] : jsonData)
      if (jsonData.error) {
        shipmentSnackbar.newMessage({ message: 'Error getting products', severity: 'failure' })
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting products', severity: 'failure' })
      setIsLoading(false);
    }
  }
  
  useEffect(() => {
    if ((productsData || []).length === 0) {
      fetchProducts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormField
      style={{ flex: 1 }}
      fieldId={!isUseSku ? 'id' : 'sku'}
      type='search'
      label='Product'
      formData={formData}
      options={filteredOptions}
      onChange={(val) => setSelectedVal(val)}
      onSearch={(val) => setSearchVal(val)}
      isLoading={isLoading}
      maxHeight='200px'
      // search field stretches over it's default size because of the clear ('x') button? we can specify a size if needed.
      //width='350px'
    />
  )
}