import { useEffect, useState } from "react"
import { createPortal } from "react-dom";
import { FaClipboard } from "react-icons/fa";
import styled from "styled-components";
import { Button, LoadingIcon, Modal } from "@prosolve/common-components-ui"
import useFetch from "../../hooks/useFetch";
import ActionButton from "../Shared/ActionButton";
import useFetchOpportunities from "../Shared/useFetchOpportunities";
import useFetchOrders from "../Shared/useFetchOrders";
import { useRefreshOpportunity } from "./RefreshOpportunityButton";
import OpportunityCard from "./OpportunityCard";
import OrderList from "../Orders/OrderList";

const Row = styled.div`
  display: flex;
  gap: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  scrollbar-gutter: stable;
  overflow: hidden; 

  &.scrollable {
    overflow: auto; 
    height: 90%;
    border-top: solid 1px rgb(0 0 0 / 10%);
    border-bottom: solid 1px rgb(0 0 0 / 10%);
    padding: 10px 15px 10px 0;
  }
`

const Column = styled.div`
  flex: 1;

  &.sticky {
    position: sticky;
    top: 0;
  }
`

const ColumnHeader = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #565656;
  padding-left: 5px;
`

export const ManageOpportunityModal = ({ 
  onClose: onCloseParam, 
  opportunityId, 
  opportunity: opportunityParam 
}) => {
  //console.log('isManageModalOpen .opportunityId', opportunityId, ', .opportunityParam', opportunityParam)
  const [opportunity, setOpportunity] = useState(opportunityParam)

  const [isLoadingOpp, setIsLoadingOpp] = useState(!opportunity)
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState()

  const fetch = useFetch();

  const { fetchOpportunities: fetchOpportunitiesHook } = useFetchOpportunities()
  const fetchOpportunityRefreshHook = useRefreshOpportunity()
  const { fetchOrders: fetchOrdersHook } = useFetchOrders()

  const fetchOpportunities = async () => {
    setIsLoadingOpp(true)
    try {
      const resultJson = await fetchOpportunitiesHook({ fetch, opportunityId: (opportunityId || opportunity.Id) })
      setOpportunity(resultJson[0])
    } finally {
      setIsLoadingOpp(false)
    }
  }

  const fetchOpportunityRefresh = async () => {
    setIsLoadingOpp(true)
    try {
      await fetchOpportunityRefreshHook(opportunityId || opportunity.Id)
    } finally {
      setIsLoadingOpp(false)
    }
  }

  const fetchOrders = async () => {
    setIsLoading(true)
    try {
      const data = await fetchOrdersHook({ fetch, opportunityId: (opportunityId || opportunity.Id) })
      const orders = data.orders
      setOrders(data.error ? [] : orders)
    } finally {
      setIsLoading(false)
    }
  }

  const onOpen = () => {
    const fetches = async () => {
      var asyncs = []
      if (!opportunity) {
        asyncs.push(fetchOpportunities())
      }
      if (!orders) {
        asyncs.push(fetchOrders())
      }
      await Promise.all(asyncs)
    }
    fetches()
    document.documentElement.style.overflow = 'hidden'
  }

  const onClose = () => {
    onCloseParam?.(false)
    document.documentElement.style.overflow = 'auto'
  }

  const onOrdersUpdate = async () => {
    await fetchOrders()
    await fetchOpportunities()
  }

  const onOpportunityUpdate = () => {
    fetchOpportunities()
  }

  const updateSbOrderData = async () => {
    // Orders update in place
    //fetchOrders()
    await fetchOpportunityRefresh()
    await fetchOpportunities()
  }

  useEffect(() => {
    onOpen()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      <Button onClick={onClose} $colored={true}>
        {'Close'}
      </Button>
    </div>
  )

  const modal = (
    <Modal title={'Manage Opportunity'} onClose={onClose} footer={footer} isFullScreen={true}>
      <Row>
        <ColumnHeader>Opportunity Details</ColumnHeader>
        <ColumnHeader style={{ flex: '2' }}>Orders {orders?.length ? `(${orders.length})` : ''}</ColumnHeader>
      </Row>
      <Row className="scrollable">
        <Column className="sticky">
          {
            isLoadingOpp ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '500px' }}>
                <LoadingIcon />
              </div>
            ) : (
              <OpportunityCard opportunity={opportunity} showManageButton={false} onCreateOrder={onOrdersUpdate} onOpportunityUpdate={onOpportunityUpdate} maxLineItemsHeight={'calc(100vh - 335px)'} />
            )
          }
        </Column>
        <Column style={{ flex: '2' }}>
          {
            isLoading ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '500px' }}>
                <LoadingIcon />
              </div>
            ) : ((orders || []).length > 0) ? (
              <OrderList orders={orders} updateOrderData={onOrdersUpdate} updateSbOrderData={updateSbOrderData} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: 0.1, height: '500px' }}>
                <div style={{ fontSize: '100px', lineHeight: 0 }}><FaClipboard /></div>
                <div style={{ fontSize: '24px' }}>No Orders</div>
              </div>
            )
          }
        </Column>
      </Row>
    </Modal>
  )

  return (<>
    {createPortal(modal, document.body)}
  </>)
}

export default ({
  // Must provide opportunity id or opportunity. If opportunity id is supplied, opportunity data will be queried when opened.
  opportunityId,
  opportunity
}) => {
  
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen && 
        <ManageOpportunityModal onClose={() => setIsOpen(false)} opportunityId={opportunityId} opportunity={opportunity} />
      }
      <ActionButton onClick={() => setIsOpen(true)}>Manage Opportunity</ActionButton>
    </>
  )
}