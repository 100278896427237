import WRODetails from "./WRODetails"

export default ({
  wros,
  updateWROData
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '10px' }}>
      {wros.map(wro => {
        return <WRODetails key={wro.id} wro={wro} updateWROData={updateWROData} />
      })}
    </div>
  )
}