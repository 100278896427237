import { useEffect, forwardRef, useImperativeHandle } from "react"
import { FormField, useForm } from "@prosolve/common-components-ui"
import ProductDropdown from "../../Shared/ProductDropdown"

export default forwardRef(({
  product,
  onChange,
  onRemove,
}, ref) => {
  const isUseSku = true
  const formData = useForm({
    requiredFields: [(!!isUseSku ? 'sku' : 'id'), 'quantity'],
    optionalFields: [],
    initData: product
  })

  useEffect(() => {
    if (!!onChange) onChange(formData.values)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values])

  useImperativeHandle(ref, () => ({
    isValid: formData.isValid
  }), [formData.isValid])

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <ProductDropdown formData={formData} isUseSku={isUseSku} />
      <FormField
        style={{ flex: 1 }}
        fieldId='quantity'
        type='text'
        label='Quantity'
        formData={formData}
      />
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'flex-end', 
        position: 'relative',
        bottom: '10px',
        cursor: 'pointer', 
        color: 'red', 
        fontWeight: 'bold' 
      }} onClick={() => onRemove(product)}>X</div>
    </div>
  )
})