//import OrderDetails from "./OrderDetails/OrderDetails"
import OrderDraftDetails from "./OrderDraftDetails/OrderDetails"

export default ({
  orders,
  updateOrderData,
  updateSbOrderData
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '10px' }}>
      {orders.map((o,i) => {
        return (o.dataSb ? 
          <OrderDraftDetails key={'sb_' + (o.dataSb.id || i)} orderEx={o} updateOrderData={updateOrderData} updateSbOrderData={updateSbOrderData} /> :
          <OrderDraftDetails key={o.data._id || i} orderEx={o} updateOrderData={updateOrderData} updateSbOrderData={updateSbOrderData} />
        )
      })}
    </div>
  )
}