import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { FormField, useForm } from "@prosolve/common-components-ui"
import AccountDropdown from "../../Shared/AccountDropdown"
import ImplementationSelector from "../../Shared/ImplementationSelector"
import ProductList from "./ProductList"
import ContactSelector from "./ContactSelector"
import styled from "styled-components"

const Row = styled.div`
  display: flex;
  gap: 15px;
`

const SectionRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 15px;
  //border: solid 1px rgb(2 128 198 / 50%);
  //border: solid 1px rgb(0 0 0 / 10%);
  border-top: solid 3px rgb(2, 128, 198);
  border-left: solid 1px rgb(0 0 0 / 10%);
  border-right: solid 1px rgb(0 0 0 / 10%);
  border-bottom: solid 1px rgb(0 0 0 / 10%);
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  background: rgba(0, 0, 0, 0.01);
`

const SectionHeader = styled.div`
  color: gray;

  //border-bottom: solid 1px #0280c6;
`

export default forwardRef(({
  formSchema,
  onFormValueChange
}, ref) => {
  const formData = useForm(formSchema)
  const [accountId, setAccountId] = useState(formSchema.initData?.accountId)
  const [implementationId, setImplementationId] = useState(formSchema.initData?.implementationId)
  const [products, setProducts] = useState(formSchema.initData?.products)
  const [contactSearch, setContactSearch] = useState()
  const productListRef = useRef()
  //console.log('createorderform refresh formSchema', formSchema, ', formData', formData)

  useImperativeHandle(ref, () => ({
    isValid: () => {
      // Note, don't use && because that will short-circuit the evaluation if the first is true and never run the second
      return formData.isValid() & productListRef.current.isValid()
    }
  }), [formData])

  useEffect(() => {
    formData.setValues(formSchema.initData || {})
    setAccountId(formSchema.initData?.accountId)
    setImplementationId(formSchema.initData?.implementationId)
    setProducts(formSchema.initData?.products)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchema.initData])

  useEffect(() => {
    const values = {
      ...formData.values,
      accountId,
      implementationId,
      products,
      contactSearch
    }
    onFormValueChange(values)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values, accountId, implementationId, products, contactSearch])

  return (
    <SectionRoot>
      <Section>
        <SectionHeader>Account</SectionHeader>
        <Row>
          <AccountDropdown value={accountId} onChange={(val) => setAccountId(val)} />
          {/* TODO create an opportunity selector */}
          <FormField
            style={{ flex: 1, display: 'none' }}
            fieldId='opportunityId'
            type='text'
            label='Opportunity'
            formData={formData}
          />
          <ImplementationSelector accountId={accountId} value={implementationId} onChange={(val) => setImplementationId(val)} />
        </Row>
      </Section>

      <Section>
        <SectionHeader>Contact</SectionHeader>
        <Row>
          <ContactSelector accountId={accountId} onChange={setContactSearch} />
        </Row>
        <Row>
          <FormField
            style={{ flex: 1 }}
            fieldId='contactName'
            type='text'
            label='Contact Name'
            formData={formData}
          />
        </Row>
        <Row>
          <FormField
            style={{ flex: 1 }}
            fieldId='contactEmail'
            type='text'
            label='Contact Email'
            formData={formData}
          />
          <FormField
            style={{ flex: 1 }}
            fieldId='contactPhone'
            type='text'
            label='Contact Phone'
            formData={formData}
          />
        </Row>
        <Row>
          <FormField
            style={{ flex: 1 }}
            fieldId='address'
            type='text'
            label='Shipping Address'
            formData={formData}
          />
        </Row>
        <Row>
          <FormField
            style={{ flex: 1 }}
            fieldId='city'
            type='text'
            label='City'
            formData={formData}
          />
          <FormField
            style={{ flex: 1 }}
            fieldId='state'
            type='text'
            label='State'
            formData={formData}
          />
          <FormField
            style={{ flex: 1 }}
            fieldId='zip'
            type='text'
            label='Zip'
            formData={formData}
          />
        </Row>
      </Section>

      <Section>
        <SectionHeader>Dates</SectionHeader>
        <Row>
          <FormField
            style={{ flex: 1 }}
            fieldId='desiredArrivalDate'
            type='date'
            label='Desired Arrival Date'
            formData={formData}
          />
          <FormField
            style={{ flex: 1 }}
            fieldId='earliestArrivalDate'
            type='date'
            label='Earliest Arrival Date'
            formData={formData}
          />
          <FormField
            style={{ flex: 1 }}
            fieldId='shippingInstructions'
            type='text'
            label='Special Shipping Instructions'
            formData={formData}
          />
        </Row>
        <Row>
          <FormField
            style={{ flex: 1 }}
            fieldId='requester'
            type='text'
            label='Requester'
            formData={formData}
          />
          <FormField
            style={{ flex: 1 }}
            fieldId='requestedDate'
            type='date'
            label='Date Requested'
            formData={formData}
          />
        </Row>
      </Section>

      <Section>
        <SectionHeader>Products</SectionHeader>
        <Row>
          <ProductList ref={productListRef} products={products} onChange={setProducts} />
        </Row>
      </Section>
    </SectionRoot>
  )

})
