export default () => {
  const addSnackbar = () => {
    const snackbar = document.createElement('div')
    document.body.appendChild(snackbar)

    snackbar.id = 'shipmentSnackbar'
    snackbar.style= `
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: fixed;
      top: 15px;
      left: calc(50% - 150px);
      min-width: 150px;
      width: 300px;
      z-index: 110000;
    `

    return snackbar
  }

  const addMessage = ({ message, severity, autoCloseDuration = 5000 }) => {
    const snackbar = document.querySelector('#shipmentSnackbar') || addSnackbar()
    const alert = document.createElement('div')
    snackbar.appendChild(alert)

    alert.style = `
      display: flex;
      gap: 10px;
      color: white;
      background: #565656;
      border-radius: 5px;
      padding: 15px 30px;
      box-shadow: 1px 2px 5px rgb(0 0 0 / 50%);

      opacity: 0;
      transition: opacity 1s ease-in-out;
    
      ${severity === 'success' ? `
        background: rgb(2, 128, 198);
      ` : ''}
    
      ${severity === 'failure' ? `
        background: #ef0c0c;
      ` : ''}
    `
    alert.innerHTML = `
      <div style='flex: 1'>
        ${message}
      </div>
    `

    const closeButton = document.createElement('div')
    alert.append(closeButton)
    const closeAction = () => {
      alert.style.opacity = 0

      setTimeout(() => {
        alert.remove()
      }, 2000)
    }

    closeButton.style = `
      cursor: pointer;
    `
    closeButton.innerHTML = `
      X
    `
    closeButton.onclick = () => {
      closeAction()
    }

    setTimeout(() => {
      alert.style.opacity = 1
    }, 1)

    if (autoCloseDuration) {
      setTimeout(() => {
        closeAction()
      }, autoCloseDuration)
    }

    return alert
  }

  return {
    /**
     * Example:
     *  shipmentSnackbar.newMessage({ message: 'my message', severity: 'success' })
     */
    newMessage: (options) => {
      addMessage(options)
    }
  }
}