import { FaSync } from "react-icons/fa"
import useFetch from "../../hooks/useFetch";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import useFetchOpportunities from "../Shared/useFetchOpportunities";

export const useRefreshOpportunity = () => {
  const fetch = useFetch()
  const shipmentSnackbar = useShipmentSnackbar()
  const { fetchOpportunityLineItemRefresh } = useFetchOpportunities()

  const fetchOpportunityRefresh = async (opportunityId) => {
    try {
      const options = { 
        opportunityId
      }
      const result = await fetchOpportunityLineItemRefresh({ fetch, ...options })

      if (result.error) {
        throw new Error(result.error)
      }
      return result.orders
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error refreshing order(s)', severity: 'failure' })
      return { error: e }
    }
  }

  return fetchOpportunityRefresh
}

export default ({
  text = 'Refresh',
  opportunity,
  onRefreshStart,
  onRefreshComplete
}) => {
  const { fetchOpportunities } = useFetchOpportunities()
  const refreshOpportunity = useRefreshOpportunity()
  
  const fetch = useFetch()

  const onClick = async () => {
    if (onRefreshStart?.() === false) return
    await refreshOpportunity(opportunity?.Id)
    const opp = await fetchOpportunities({ fetch, opportunityId: opportunity?.Id })
    onRefreshComplete?.(opp)
  }

  return (
    <FaSync title={text} onClick={onClick} style={{ cursor: 'pointer' }} />
  )
}