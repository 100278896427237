import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FadeInWrapper, Footer, LoadingIcon, Main, MainContent, Section } from "@prosolve/common-components-ui";
import useIsMobileView from "../../hooks/useIsMobileView";
import useFetch from "../../hooks/useFetch";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import useFetchOpportunities from "../Shared/useFetchOpportunities";
import OpportunityCards from "./OpportunityCards";
import OpportunityFilters from "./OpportunityFilters";
import RefreshOpportunityButton from "./RefreshOpportunityButton";

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const isMobileView = useIsMobileView();
  const fetch = useFetch();
  const shipmentSnackbar = useShipmentSnackbar()
  const { fetchOpportunities: fetchOpportunitiesHook } = useFetchOpportunities()
  
  const [searchParams] = useSearchParams()
  const [reportType, setReportType] = useState(searchParams.get('reportType'))

  const [isLoading, setIsLoading] = useState(true)
  const [isOpportunityDataAvailable, setIsOpportunityDataAvailable] = useState(false)
  const [opportunities, setOpportunities] = useState([])
  const [filteredOpportunities, setFilteredOpportunities] = useState(opportunities)

  const fetchOpportunities = async () => {
    setIsLoading(true)
    try {
      //const result = await fetchOpportunitiesHook({ limit: 50, reportType, accountId: selectedAccount, territory })
      const resultJson = await fetchOpportunitiesHook({ fetch })
      setOpportunities(resultJson)
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting opportunities', severity: 'failure' })
    } finally {
      setIsOpportunityDataAvailable(true)
    }
  }

  const onFilteredOpportunitiesChange = (newFilteredOpportunities) => {
    setFilteredOpportunities(newFilteredOpportunities)
    if (isOpportunityDataAvailable) setIsLoading(false)
  }

  const onRefreshStart = () => {
    const confirm = window.confirm('Are you sure you want to refresh all Opportunities?')
    if (confirm) {
      setIsLoading(true)
    }
    return confirm
  }

  const onRefreshComplete = () => {
    fetchOpportunities()
  }

  useEffect(() => {
    fetchOpportunities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setReportType(searchParams.get('reportType'))
  }, [searchParams])

  return (
    <Main $isMobileView={isMobileView} $backgroundColor={'inherit'} style={{ padding: '10px' }}>
      <MainContent $isMobileView={isMobileView}>
        <div style={{ alignItems: 'center', marginBottom: '20px' }}>
          <div style={{ fontSize: '28px', fontWeight: 'bold' }}>
            {'Manage Opportunities'}
            <span style={{ paddingLeft: '10px', fontSize: '1rem' }}>
              <RefreshOpportunityButton text='Refresh All Opportunities' onRefreshStart={onRefreshStart} onRefreshComplete={onRefreshComplete} />
            </span>
          </div>
          <div style={{ color: 'gray', fontSize: '0.8em', marginTop: '5px' }}>
            {'(Up to 50 entries displayed at a time)'}
          </div>
        </div>
        <div>
          <OpportunityFilters filter={{ reportType }} opportunities={opportunities} onFilteredOpportunitiesChange={onFilteredOpportunitiesChange} />
        </div>
        <div style={{ padding: '0' }}>
        { 
          isLoading 
          ?
          <Section style={{ background: 'inherit', height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          !filteredOpportunities?.length ?
          <Section style={{ background: 'inherit', height: '400px' }}>
            Unable to find opportunityData data
          </Section>
          :
          <FadeInWrapper>
            <Section style={{ background: 'inherit', padding: '0px' }}>
              <OpportunityCards opportunities={filteredOpportunities} />
            </Section>
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}