import { useEffect, useState } from "react"
import ToggleButton from "../Shared/ToggleButton"
import AccountDropdown from "../Shared/AccountDropdown"
import useOpportunityReportType from "../Shared/useOpportunityReportType"

export default ({
  filter: defaultFilter,
  opportunities: defaultOpportunities,
  onFilteredOpportunitiesChange
}) => {
  const [filter, setFilter] = useState(defaultFilter || {})
  const [opportunities, setOpportunities] = useState(defaultOpportunities || [])

  const { reportTypes, filterOpportunitiesByReportType } = useOpportunityReportType()

  const { accountId, reportType } = filter

  const onAccountChange = (val) => {
    const filterClone = {
      ...filter,
      accountId: val
    }
    setFilter(filterClone)
  }

  const onReportTypeChange = (val) => {
    const filterClone = {
      ...filter,
      reportType: (filter.reportType === val ? undefined: val)
    }
    setFilter(filterClone)
  }

  const updateFilteredOpportunities = () => {
    const filteredOpportunities = filterOpportunitiesByReportType(opportunities, reportType).filter(o => {
      if (accountId && o.AccountId !== accountId) return false
      return true
    }).slice(0, 50)
    //console.log('updateFilteredOpportunities', 'filter', filter, '.length', opportunities.length)
    onFilteredOpportunitiesChange(filteredOpportunities)
  }

  useEffect(() => {
    setOpportunities(defaultOpportunities)
  }, [defaultOpportunities])

  useEffect(() => {
    updateFilteredOpportunities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunities, filter])

  return (
    <>
      <div style={{ paddingBottom: '20px' }}>
        <AccountDropdown onChange={onAccountChange} />
      </div>

      <div style={{ paddingBottom: '20px' }}>
        {reportTypes.map(r => (
          <ToggleButton key={r.id} on={(r.id === reportType).toString()} onClick={() => { onReportTypeChange(r.id) }}>{r.buttonTitle}</ToggleButton>
        ))}
      </div>
    </>
  )
}