import { useState, useEffect } from 'react';

export default (query) => {
  const initMediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(initMediaMatch.matches);

  useEffect(() => {
    const mediaMatch = window.matchMedia(query);
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return matches;
};