import Cookies from 'js-cookie';

export const addPlural = (val) => val > 1 ? 's' : '';

export const checkHasShipmentExperimentalEnabled = () => {
  const adminPermissionsCookies = Cookies.get('shipmentExperimental') || ''
  return (adminPermissionsCookies.length > 0)
}

export const checkHasPermissionShipmentTrackingRead = () => {
  const adminPermissionsCookies = Cookies.get('adminPermissions') || ''
  return (adminPermissionsCookies.indexOf('SHIPMENT_TRACKING_READ') > -1)
}

export const checkHasPermissionShipmentTrackingEdit = () => {
  const adminPermissionsCookies = Cookies.get('adminPermissions') || ''
  return (adminPermissionsCookies.indexOf('SHIPMENT_TRACKING_EDIT') > -1)
}

export const checkHasPermissionShipmentTrackingPublish = () => {
  const adminPermissionsCookies = Cookies.get('adminPermissions') || ''
  return (adminPermissionsCookies.indexOf('SHIPMENT_TRACKING_PUBLISH') > -1)
}

export const checkHasPermissionToShippingApp = () => {
  const adminPermissionsCookies = Cookies.get('adminPermissions') || ''
  return (
    (adminPermissionsCookies.indexOf('SHIPMENT_TRACKING_READ') > -1) ||
    (adminPermissionsCookies.indexOf('SHIPMENT_TRACKING_EDIT') > -1) ||
    (adminPermissionsCookies.indexOf('SHIPMENT_TRACKING_PUBLISH') > -1)
  )
}

export const checkHasPermissionToAssessmentManagerApp = () => {
  const adminPermissionsCookies = Cookies.get('adminPermissions') || ''
  return (
    (adminPermissionsCookies.indexOf('ASSESSMENT_BUILDER_READ') > -1) ||
    (adminPermissionsCookies.indexOf('ASSESSMENT_BUILDER_EDIT') > -1) ||
    (adminPermissionsCookies.indexOf('ASSESSMENT_REPORTS_READ') > -1)
  )
}

export const checkHasPermissionToQuestCodesApp = () => {
  const adminPermissionsCookies = Cookies.get('adminPermissions') || ''
  return (
    (adminPermissionsCookies.indexOf('QUEST_CODES_READ') > -1) ||
    (adminPermissionsCookies.indexOf('QUEST_CODES_EDIT') > -1)
  )
}

const allowedClientSubdomains = ['star']
export const allowedStandardDomains = ['go','localhost:3000']
const allAllowedSubDomains = [ ...allowedClientSubdomains, ...allowedStandardDomains ]

export const getClientSubdomain = () => {
  const originFull = window.origin;
  const splitVal = originFull.indexOf('https://') > -1 ? 'https://' : 'http://';
  const origin = originFull.split(splitVal)[1] || '';
 
  const parts = origin.split('.');
  const firstPart = parts[0];

  if (allAllowedSubDomains.indexOf(firstPart) > -1) {
    return firstPart
  }
  else {
    return ''
  }
}
/**
 * Transforms an array of objects into a key -> value map for O(0) fast lookups
 * @param {[{}]} arr - an array of objects
 * @param {string} key - a string representing a property present in all objects
 * 
 * Usage: 
 *   const arr = [{id: 1, name: 'one'}, {id: 2, name; 'two'}]
 *   const map = arrayToMap(arr, 'name');
 *   console.log(map['one']);
 */
export const arrayToMap = (arr, key) => {
  return arr.reduce((prev, curr) => {
    prev[curr[key]] = curr
    return prev
  }, {})
}

/**
 * Left joins two arrays of objects based on a common key (this does not handle 1-to-many relations)
 * @param {[{}]} arr1 - an array of objects
 * @param {string} key1 - a string representing a property present in all objects of arr1
 * @param {[{}]} arr2 - an array of objects
 * @param {string} key2 - a string representing a property present in all objects of arr2
 * @param {string | (arr1obj: object, arr2obj: object)} merge - a string to copy the joined arr2 value into or a function to perform the merge
 * 
 * Usage: 
 *   const arr1 = [{id: 1, name: 'one'}, {id: 2, name; 'two'}]
 *   const arr2 = [{id: 12345, name: 'one', data: 'A7BB2'}, {id: 98765, name; 'two', data: 'B990CA'}]
 *   arrayLeftJoin(arr1, 'name', arr2, 'name', 'extraData');
 *   console.log(arr1[0].extraData.data);
 */
export const arrayLeftJoin = (arr1, key1, arr2, key2, merge) => {
  //const arr1Map = arrayToMap(arr1, key1)
  const arr2Map = arrayToMap(arr2, key2)
  const isMergeString = (typeof(merge) === 'string')

  arr1.forEach(a1 => {
    const a2 = arr2Map[a1[key1]]
    if (isMergeString) a1[merge] = a2
    else merge(a1, a2)
  })

  return arr1
}

/**
 * Forces the browser to download the supplied file data
 * @param {*} filename - The name of the file to export
 * @param {*} fileDataParts - The data to download, an array can be supplied if the file should be downloaded in chunks
 * @param {*} type - The file type expressed to the browser (default: 'text/plain')
 */
export const downloadFile = ({ filename, fileDataParts, type = 'text/plain' }) => {
  const blob = new Blob(fileDataParts, { type })
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove()
}
