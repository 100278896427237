import { FaSync } from "react-icons/fa"
import useFetch from "../../hooks/useFetch";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import useFetchOrders from "../Shared/useFetchOrders";

export const useRefreshOrder = () => {
  const fetch = useFetch()
  const shipmentSnackbar = useShipmentSnackbar()
  const { fetchOrders } = useFetchOrders()

  const fetchOrderRefresh = async (order) => {
    try {
      const options = { 
        orderId: (order?.source === 'db' ? order?._id : undefined), 
        // TODO even though we specify this, fetchOrders limits the result by DB filters, not SB filters, so we'll get EVERY DB record back.
        //   Luckily this works for finalize because it orders by most recently changed, but this is a problem.
        shipbobOrderId: (order?.source === 'sb' ? order?.id : undefined),
        useCache: false,
        ...(order ? {} : { limit: 0 }) 
      }
      //console.log('order', order, 'options', options, '(order ? {} : { limit: 0 })', (order ? {} : { limit: 0 }))
      const result = await fetchOrders({ fetch, ...options })

      if (result.error) {
        throw new Error(result.error)
      }
      return result.orders
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error refreshing order(s)', severity: 'failure' })
      return { error: e }
    }
  }

  return fetchOrderRefresh
}

export default ({
  text = 'Refresh',
  order,
  onRefreshStart,
  onRefreshComplete
}) => {
  const refreshOrder = useRefreshOrder()

  const onClick = async () => {
    if (onRefreshStart?.() === false) return
    const orders = await refreshOrder(order)
    onRefreshComplete?.(orders)
  }

  return (
    <FaSync title={text} onClick={onClick} style={{ cursor: 'pointer' }} />
  )
}