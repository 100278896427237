import { Button } from "@prosolve/common-components-ui"
import styled from "styled-components"

export default styled(Button).attrs(props => ({ 
  // TODO figure out how to pass as boolean
  $colored: (props.on === 'true'),
}))`
  height: 26px;
  margin: 0 5px;
  border-radius: 20px;
  display: inline-flex;
`