import { useState } from "react";
import styled from "styled-components"
import { ManageOpportunityModal } from "../Opportunities/ManageOpportunityButton";

const Card = styled.div`
  padding: 5px 20px 0 20px;

  &:hover {
    background-color: rgb(240, 250, 255);
    cursor: pointer;
  }
`

const Row = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 5px 10px 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.opportunity {
    flex-grow: 1;
    align-items: start;
  }
`

export default ({
  opportunity
}) => {
  const {
    Id,
    Name,
    OpportunityLineItems,
  } = opportunity

  const [isManageOppOpen, setIsManageOppOpen] = useState(false)

  const remainingToShipCount = OpportunityLineItems?.records?.reduce((acc, rec) => acc + (rec.Quantity || 0), 0) || 0
  const requested = OpportunityLineItems?.records?.reduce((acc, rec) => acc + (rec.Units_Requested__c || 0), 0) || 0
  const shippedCount = OpportunityLineItems?.records?.reduce((acc, rec) => acc + (rec.Units_Shipped__c || 0), 0) || 0

  return (
    <>
      <Card onClick={() => setIsManageOppOpen(true)}>
        <Row>
          <Column style={{ flex: '1', alignItems: 'start', minWidth: '150px' }}>
            <div>{Name}</div>
          </Column>
          <Column>
            <div style={{ color: 'rgb(2, 128, 198)', fontSize: '32px' }}>{remainingToShipCount}</div>
            <div style={{ color: 'gray' }}>Purchased</div>
          </Column>
          <Column>
            <div style={{ color: 'rgb(2, 128, 198)', fontSize: '32px' }}>{requested}</div>
            <div style={{ color: 'gray' }}>Requested</div>
          </Column>
          <Column>
            <div style={{ color: 'rgb(2, 128, 198)', fontSize: '32px' }}>{shippedCount}</div>
            <div style={{ color: 'gray' }}>Shipped</div>
          </Column>
        </Row>
      </Card>
      {isManageOppOpen &&
        <ManageOpportunityModal onClose={() => setIsManageOppOpen(false)} opportunityId={Id} />
      }
    </>
  )
}