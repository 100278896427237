import { useEffect, useState } from "react";
import { FadeInWrapper, Footer, LoadingIcon, Main, MainContent, Section } from "@prosolve/common-components-ui";
import useIsMobileView from "../../hooks/useIsMobileView";
import useFetch from "../../hooks/useFetch";
import useShipmentSnackbar from "../Shared/useShipmentSnackbar";
import WROList from "./WROList";
import CreateWROButton from "./CreateWROButton";

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const isMobileView = useIsMobileView();
  const fetch = useFetch()

  const [isLoading, setIsLoading] = useState(true)
  const [WROs, setWROs] = useState([])
  
  const shipmentSnackbar = useShipmentSnackbar()

  const fetchWROs = async () => {
    setIsLoading(true)
    try {
      const result = await fetch('shipping/wro')
      const resultJson = await result.json()
      const resultSorted = resultJson.sort((a,b) => (a.status === 'Cancelled' ? 1 : -1))
      setWROs(resultSorted)
    } catch (e) {
      console.error(e)
      shipmentSnackbar.newMessage({ message: 'Error getting WROs', severity: 'failure' })
    } finally {
      setIsLoading(false)
    }
  }

  const updateWROData = (data, isCreate) => {
    if (isCreate) {
      const newWRO = [ ...[data], ...WROs ]
      setWROs(newWRO)
    } else {
      const prodIndex = WROs.findIndex(p => p.id === data.id)
      const clonedProducts = [ ...WROs ]
      clonedProducts.splice(prodIndex, 1)
      setWROs(clonedProducts)
    }
  }

  useEffect(() => {
    fetchWROs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main $isMobileView={isMobileView} $backgroundColor={'inherit'} style={{ padding: '10px' }}>
      <MainContent $isMobileView={isMobileView}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{
            fontSize: '28px',
            fontWeight: 'bold'
          }}>
            {'Warehouse Receiving Orders'}
          </div>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <CreateWROButton updateWROData={updateWROData} />
        </div>
        <div style={{ padding: '0px' }}>
        { 
          isLoading 
          ?
          <Section style={{ background: 'inherit', height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          !WROs?.length ?
          <Section style={{ background: 'inherit', height: '400px' }}>
            Unable to find WRO data
          </Section>
          :
          <FadeInWrapper>
            <Section style={{ background: 'inherit', padding: '0px' }}>
              <WROList wros={WROs} updateWROData={updateWROData} />
            </Section>
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}